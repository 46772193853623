<template>
  <div class="main__run">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h4 class="title text-center mb-3">
            Run on your own infrastructure or ours
          </h4>
        </div>
        <div
          class="col-md-12 d-flex align-items-center justify-content-center my-4"
        >
          <div class="mode-selector">
            <button
              class="btn"
              :class="{ active: previewMode === 'cli' }"
              @click="previewMode = 'cli'"
            >
              <span>Monk CLI</span></button
            ><button
              class="btn"
              :class="{ active: previewMode === 'gui' }"
              @click="previewMode = 'gui'"
            >
              <span>Monk GUI</span>
            </button>
          </div>
        </div>
        <div class="col-md-12 main__details-item">
          <div class="row">
            <div class="col-md-6 p-3 p-lg-6">
              <div class="script-terminal">
                <pre v-html="code" />
              </div>
            </div>
            <div class="col-md-6 p-3 p-lg-6 item-info-content">
              <p class="item-desc mb-3 mb-lg-5">
                Separated they live in Bookmarks right at the coast of the
                famous Semantics, large language ocean Separated they live in
                Bookmarks right at the coast
              </p>
              <div class="d-flex flex-column">
                <div class="item-details mb-md-5">
                  <div
                    v-for="(i, index) in items"
                    :key="index"
                    class="item-details__item mb-3 mb-lg-0 pr-3"
                  >
                    <span class="item-details__item-title">{{ i.title }}</span>
                    <p class="mt-1 mt-lg-2">
                      {{ i.desc }}
                    </p>
                  </div>
                </div>
                <a href="#" class="simple-btn simple-shadow develop-own-btn">
                  <span>Develop your own Kit</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      previewMode: "cli",
      items: [
        {
          title: "Browse",
          desc: "Browse off the shelf packages of any complexity (e.g. from a single Nginx server to entire AirByte pipeline) made by experts like you.",
        },
        {
          title: "Import",
          desc: "Import an entire stack just as you would a code library, without rewriting code, infrastructure, or configuration.",
        },
      ],
    };
  },
  computed: {
    code() {
      const cli = `~ brew install monk-io/monk/monk
==> Downloading https://get.monk.io/stable/macos/monk-darwin-v3.3.0.tar.gz`;
      const gui = `~ brew install GUI`;
      return this.previewMode === "cli" ? cli : gui;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__run {
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
  padding-top: 130px;
  padding-bottom: 80px;
  background-color: #f8f9ff;

  .item-desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 30px;
  }

  .item-details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .item-details__item {
      width: 50%;
      font-weight: 250;
      font-size: 14px;
      line-height: 20px;
    }

    .item-details__item-title {
      font-size: 16px;
      font-weight: 500;
    }

    .simple-link {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }
  }

  .mode-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 312px;
    height: 48px;
    padding: 4px;
    background: rgba(27, 36, 67, 0.1);
    border-radius: 40px;

    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      height: 40px;
      border-radius: 40px;
      overflow: hidden;
      outline: none;
      box-shadow: none;
      background-color: transparent;
      transition: all 0.2s;

      &.active {
        background-color: #ffffff;
      }

      &.active,
      &:hover {
        span {
          opacity: 1;
        }
      }

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 2px;
        color: #0f141d;
        opacity: 0.5;
        transition: all 0.2s;
      }
    }
  }

  .script-terminal {
    height: 390px;
    width: 100%;
    padding: 0;
    background: #1b2443 !important;
    border: 0.7px solid #0f141d;
    box-shadow: 0 15px 0 -8px #0f141d;

    pre {
      flex: 1;
      width: 100%;
      font-family: "Lineto", monospace !important;
      padding: 35px;
      color: #ffffff;
      font-size: 16px;
      line-height: 30px;
      white-space: pre-line;
      word-break: break-all;
      background-color: inherit;
    }
  }

  .develop-own-btn {
    min-width: 160px;
    max-width: 300px;
  }

  @include media-breakpoint-down(md) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  @include media-breakpoint-down(sm) {
    .item-details {
      flex-direction: column;

      .item-details__item {
        width: 100%;
      }
    }
  }
}
</style>
