<template>
  <div class="kit-item-page">
    <div class="container-fluid container-md">
      <div class="row">
        <div class="col-md-12">
          <div class="kit-item__content-wrap">
            <div class="kit-item__content">
              <div
                v-if="data.Tags && data.Tags.length"
                class="d-flex flex-row flex-wrap mb-2"
              >
                <span
                  v-for="(tagItem, index) in data.Tags"
                  :key="index"
                  class="tag-item mr-1 mt-1"
                  >{{ tagItem }}</span
                >
              </div>
              <p class="">
                {{ dataSource }}
                {{ data.Description }}
              </p>
              <div class="kit-item__gh-info">
                <div v-if="gh" class="kit-gn__info d-flex flex-row">
                  <span class="kit-gh__counter mr-3"
                    ><img
                      src="@/assets/icons/reload.svg"
                      alt="reload icon"
                      class="kit-gh__icon"
                    />17 minutes ago</span
                  >
                  <span v-if="gh.stars" class="kit-gh__counter mr-3"
                    ><img
                      src="@/assets/icons/star.svg"
                      alt="star icon"
                      class="kit-gh__icon"
                    />{{ gh.stars }}</span
                  >
                  <span class="kit-gh__counter mr-3"
                    ><img
                      src="@/assets/icons/download.svg"
                      alt="download icon"
                      class="kit-gh__icon"
                    />1,234
                  </span>
                </div>
                <div
                  v-if="data.Website && data.Website !== '-'"
                  class="kit-gn__info d-flex flex-column"
                >
                  <span class="kit-gh__info-title">Documentation</span>
                  <a :href="data.Website" target="_blank"
                    ><img
                      src="@/assets/icons/docs.svg"
                      alt="docs icon"
                      class="kit-gh__icon"
                    />{{ data.Website }}</a
                  >
                </div>
                <div
                  v-if="data.Source && data.Source !== '-'"
                  class="kit-gn__info d-flex flex-column"
                >
                  <span class="kit-gh__info-title">Repository</span>
                  <a :href="data.Source" target="_blank"
                    ><img
                      src="@/assets/icons/gh.svg"
                      alt="gh icon"
                      class="kit-gh__icon"
                    />{{ data.Source }}</a
                  >
                </div>
                <div v-if="gh" class="kit-gn__info d-flex flex-row flex-wrap">
                  <div v-if="gh.version" class="d-flex flex-column mr-5 mt-1">
                    <span class="kit-gh__info-title">Version</span>
                    <span>{{ gh.version }}</span>
                  </div>
                  <div v-if="gh.license" class="d-flex flex-column mr-5 mt-1">
                    <span class="kit-gh__info-title">License</span>
                    <span>{{ gh.license }}</span>
                  </div>
                  <div v-if="gh.issues" class="d-flex flex-column mr-5 mt-1">
                    <span class="kit-gh__info-title">Issues</span>
                    <span>{{ gh.issues }}</span>
                  </div>
                  <div v-if="gh.prs" class="d-flex flex-column mr-5 mt-1">
                    <span class="kit-gh__info-title">Pull Requests</span>
                    <span>{{ gh.prs }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="kit-item__preview-wrap d-none d-md-flex">
              <KitPreview
                v-if="data.Path && data.Containers"
                :template="data"
              />
            </div>
          </div>
          <DevopsBanner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import KitPreview from "./KitPreview";
import DevopsBanner from "../DevopsBanner";

export default {
  components: { KitPreview, DevopsBanner },
  data() {
    return {
      itemInfo: null,
      gh: null,
    };
  },
  mounted() {
    this.fetchKitItem();

    if (this.dataSource) this.fetchGHData();
  },
  computed: {
    pathName() {
      return this.$route.params.id || "";
    },
    data() {
      return (
        this.itemInfo ||
        this.$store.getters["templates/templateItemData"](this.pathName)
      );
    },
    dataSource() {
      return this.data.Source;
    },
  },
  methods: {
    fetchKitItem() {
      this.$store.dispatch("templates/fetchFullItemData", this.pathName);
    },
    async fetchGHData() {
      this.gh = null;

      if (!(this.dataSource && this.dataSource.match("https://github.com"))) {
        return;
      }

      const repo = this.dataSource
        .replace("https://github.com/", "")
        .replace(/\/+$/, "");

      this.fetchGH_MainInfo(repo);
      this.fetchGH_ReleasesInfo(repo);
      this.fetchGH_PRS_Info(repo);
    },
    async fetchGH_MainInfo(repo) {
      const info = await axios.get(`https://api.github.com/repos/${repo}`);

      if (info && info.data) {
        this.gh = {
          stars: info.data.stargazers_count || "",
          issues: info.data.open_issues_count || "",
          license:
            info.data.license && info.data.license.spdx_id
              ? info.data.license.spdx_id
              : "",
          prs: 0,
          version: null,
        };
      }
    },
    async fetchGH_ReleasesInfo(repo) {
      const releases = await axios.get(
        `https://api.github.com/repos/${repo}/releases`
      );

      if (releases && releases.data && releases.data.length > 0) {
        this.gh.version = releases.data[0].tag_name;
      }
    },
    async fetchGH_PRS_Info(repo) {
      const prs = await axios.get(`https://api.github.com/repos/${repo}/pulls`);

      if (prs && prs.data && prs.data.length > 0) this.gh.prs = prs.data.length;
    },
  },
  watch: {
    dataSource(newVal) {
      if (newVal) this.fetchGHData();
    },
  },
};
</script>

<style lang="scss">
.kit-item-page {
  padding-top: 50px;
  padding-bottom: 80px;

  .kit-item__content-wrap {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    padding: 36px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505e73;
    background-color: #fff;
  }

  .kit-item__content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .kit-item__preview-wrap {
    flex-shrink: 0;
    width: 320px;
    height: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 60px;
    margin-left: 70px;

    background-color: #f8f9ff;
    border: 1px solid #d9dee5;
    border-radius: 6px;
    overflow: scroll;
  }

  .tag-item {
    padding: 0px 6px;
    background-color: #eeeff4;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #848897;
  }

  .kit-gn__info {
    padding: 16px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #708099;
    border-bottom: 1px solid #d9dee5;

    a {
      color: inherit;
      font-size: inherit;
    }

    &:last-child {
      border-bottom: none;
    }

    .kit-gh__info-title {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #2e343d;
    }

    .kit-gh__icon {
      margin-right: 5px;
      max-width: 15px;
      max-height: 15px;
    }

    .kit-gh__counter {
      font-size: 12px;
      color: #2e343d;
      font-weight: 500;
    }
  }
}
</style>
