import api from "@/api/client";
import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  ITEM_DATA,
  FULL_ITEMS_LIST,
  LIST_MODE,
  SET_GROUP_FILTER,
  FULL_ITEM_DATA,
} from "./action-types";

export const actions = {
  async fetchFullItemsList(
    { commit, state, rootGetters, getters },
    { scrollLoading = false, from = null }
  ) {
    const q = rootGetters["filters/getQueryString"];
    const stateActiveFilter = rootGetters["filters/getActiveFilter"];
    const computedFilters = {};

    if (q) computedFilters.q = q;

    if (stateActiveFilter) {
      const activeFilterArray = stateActiveFilter.split(":");
      computedFilters[activeFilterArray[0]] =
        activeFilterArray[1].toLowerCase();
    }

    if (state.fullItems.pagination.isFull && scrollLoading) return;

    commit(fetched(FULL_ITEMS_LIST));

    const respNew = await api.fetchTemplates({
      filters: { ...computedFilters },
      from: scrollLoading
        ? getters["fullItemsList"].length
        : from !== null
        ? from
        : 0,
    });

    if (respNew.error) {
      const error = respNew.error ? respNew.error : "";

      commit(refused(FULL_ITEMS_LIST), error);
    } else {
      commit(fulfilled(FULL_ITEMS_LIST), {
        items: respNew.templates,
        count: respNew.count || 0,
        scrollLoading,
      });
    }
  },

  async fetchItem({ commit }, { id }) {
    commit(fetched(ITEM_DATA));

    const resp = await api.fetchTemplateItem(id);

    if (resp.error && (!resp.containers || !resp.variables)) {
      const error = resp.error ? resp.error : "";

      commit(refused(ITEM_DATA), error);
    } else {
      commit(fulfilled(ITEM_DATA), {
        id,
        data: {
          variables: resp.variables,
          containers: resp.containers,
          actions: resp.actions,
        },
      });
    }
  },

  async fetchFullItemData({ commit }, path) {
    const resp = await api.fetchKitItem(path);

    if (resp.error) {
      return;
    } else {
      commit(fulfilled(FULL_ITEM_DATA), resp);
    }
  },

  [LIST_MODE]: ({ commit }, value) => commit(LIST_MODE, value),
  [SET_GROUP_FILTER]: ({ commit }, value) => commit(SET_GROUP_FILTER, value),
};
