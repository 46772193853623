<template>
  <div class="col-md-12 list-kit-item mx-3 mx-md-0">
    <div class="data-block">
      <div class="d-flex flex-row">
        <div class="kit-img-wrap">
          <img
            v-if="!emptyIcon && data.Icon && data.Icon !== '-'"
            :src="data.Icon"
            @error="emptyIcon = true"
          />
        </div>
        <div class="d-flex flex-column ml-3 mr-lg-3">
          <div class="d-flex flex-row mb-2">
            <span class="kit-path-name">
              {{ data.Path }}
            </span>
            <span
              v-if="data.Publisher && data.Publisher !== '-'"
              class="kit-official ml-2"
              >Official publisher</span
            >
          </div>
          <p class="kit-desc mb-0">
            {{ data.Description }}
          </p>
          <div
            v-if="data.Tags && data.Tags.length"
            class="d-flex flex-row mt-2"
          >
            <span
              v-for="(tagItem, index) in data.Tags.slice(0, 4)"
              :key="index"
              class="tag-item mr-1"
              >{{ tagItem }}</span
            >
            <span v-if="data.Tags.length > 4" class="tag-item mr-1"
              >{{ data.Tags.length - 4 }} more</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="actions-block mt-3 mt-lg-0">
      <router-link
        :to="{ name: 'kit-item', params: { id: data.Path } }"
        class="simple-btn size-sm dark py-2 px-4"
      >
        <span class="text-uppercase">View Kit</span>
      </router-link>
      <button
        class="btn simple-btn size-sm blue py-2 px-4 ml-3"
        @click="onDeploy"
      >
        <span class="text-uppercase">Deploy</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "KitItem",
  props: ["data"],
  data() {
    return {
      emptyIcon: false,
    };
  },
  methods: {
    onDeploy() {
      this.$router.push(
        {
          name: this.$route.name,
          query: {
            ...this.$route.query,
            template: this.data.Path || this.data.Name,
          },
        },
        () => this.$store.dispatch("modals/showModal", "TEMPLATE_DEPLOY_SIMPLE")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap-grid";
.list-kit-item {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0 20px 0 -10px #dbdfed;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #505e73;

  .data-block {
    flex: 1;
  }

  .actions-block {
    display: flex;
    flex-direction: row;
  }

  .kit-img-wrap {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    border: 1px solid #f1f2f5;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .kit-path-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0c2665;
    word-break: break-word;
  }

  .kit-official {
    flex-shrink: 0;
    align-self: flex-start;
    padding: 2px 8px;
    background: #e8f9f7;
    border-radius: 12px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #22b9aa;
  }

  .tag-item {
    padding: 0px 6px;
    background: #eeeff4;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #848897;
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}
</style>
