import api from "@/api/client";
import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  FETCH_FILTERS,
  SET_FILTER_VALUE,
  SET_QUERY_STRING,
  SET_INITIAL,
} from "./action-types";

export const actions = {
  async fetchFilters({ commit }) {
    commit(fetched(FETCH_FILTERS));

    const newResp = await api.fetchFilters();

    if (newResp.error) {
      const error = newResp.error ? newResp.error : "";

      commit(refused(FETCH_FILTERS), error);
    } else {
      commit(fulfilled(FETCH_FILTERS), newResp);
    }
  },

  setFilterValue({ commit }, data) {
    commit(SET_FILTER_VALUE, data);
  },

  setQueryString({ commit }, value) {
    commit(SET_QUERY_STRING, value);
  },

  setInitialFilters({ commit }, filters) {
    commit(SET_INITIAL, filters);
  },
};
