<template>
  <div class="common-footer">
    <footer class="py-4 py-md-2">
      <div class="container">
        <div class="row">
          <div class="col-md-12 d-flex flex-column align-items-center">
            <!-- <div
            class="col-md-12 d-flex flex-column flex-md-row align-items-center"
          > -->
            <span class="main-info"
              >All right reserved © {{ year }} MonkOS, Inc
            </span>
            <!-- <div class="d-flex flex-row ml-md-5 mt-2 mt-md-0">
              <a
                href="https://monk.io/privacy-policy"
                target="_blank"
                class="footer-text-link"
              >
                Privacy Policy
              </a>
              <a
                href="https://monk.io/terms-of-service"
                target="_blank"
                class="ml-4 footer-text-link"
              >
                Terms of Service
              </a>
            </div>
            <div class="d-flex flex-row mt-2 mt-md-0 ml-md-auto">
              <a
                href="https://twitter.com/monk_io"
                class="mr-1"
                target="_blank"
              >
                <img
                  src="@/assets/icons/twitter-2.svg"
                  alt="twitter logo"
                  class="footer-icon"
                />
              </a>
              <a
                href="https://discord.com/invite/2YGryc5"
                class="ml-2"
                target="_blank"
              >
                <img
                  src="@/assets/icons/discord.svg"
                  alt="discord logo"
                  class="footer-icon"
                />
              </a>
            </div> -->
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss">
footer {
  background-color: #010101;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #979797;

  .footer-icon {
    width: 16px;
    height: 16px;
    filter: invert(36%) sepia(11%) saturate(653%) hue-rotate(189deg)
      brightness(94%) contrast(90%);
  }

  .footer-text-link {
    color: #ffffff;
    opacity: 0.4;
    transition: all 0.2s;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 1;
    }
  }
}
</style>
