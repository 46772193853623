<template>
  <modal
    v-if="shouldShow"
    @close="onModalCose"
    :customDialogClasses="['get-monk-modal']"
  >
    <template v-slot:body>
      <div class="get-monk-modal__content">
        <button class="btn close-btn" @click="onModalCose">
          <img src="@/assets/icons/close-pixeled.svg" alt="close icon" />
        </button>
        <span class="title mb-3"> Get Monk </span>
        <div class="row">
          <div class="col-md-6 d-flex flex-column align-items-center">
            <span class="title-sec">Monk CLI</span>
            <p class="desc mb-4">
              Change the color to match your brand or vision, add your logo,
              choose the perfect thumbnail, remove the playbar
            </p>
            <button
              class="simple-btn simple-shadow size-m mb-4"
              @click="onCopyCode"
            >
              <span>brew install monk-io/monk</span>
            </button>
            <a
              href="#"
              class="simple-link text-decoration-none black d-flex flex-row"
            >
              <span>Learn more</span>
              <img
                src="@/assets/icons/simple-arrow_black.svg"
                alt="arrow icon"
                class="simple-icon ml-2 mt-1"
              />
            </a>
            <div class="preview-block">
              <div class="script-terminal">
                <code v-html="cli" />
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex flex-column align-items-center">
            <span class="title-sec">Monk GUI</span>
            <p class="desc mb-4">
              Change the color to match your brand or vision, add your logo,
              choose the perfect thumbnail, remove the playbar
            </p>
            <a
              href="#"
              target="_blank"
              class="simple-btn simple-shadow white size-m mb-4"
            >
              <span>Coming soon - Get Notified</span>
            </a>
            <a
              href="#"
              class="simple-link text-decoration-none black d-flex flex-row"
            >
              <span>Learn more</span>
              <img
                src="@/assets/icons/simple-arrow_black.svg"
                alt="arrow icon"
                class="simple-icon ml-2 mt-1"
              />
            </a>
            <div class="preview-block">
              <img src="@/assets/img/get-monk__gui.svg" alt="gui preview" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/ui/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      cli: `~ brew install monk-io/monk/monk
==> Downloading https://get.monk.io/stable/macos/monk-darwin-v3.3.0.tar.gz`,
    };
  },
  computed: {
    shouldShow() {
      return this.$store.getters["modals/getModalState"]("GET_MONK");
    },
  },
  methods: {
    onModalCose() {
      this.$store.dispatch("modals/hideModal", "GET_MONK");
    },
    onCopyCode() {
      navigator.clipboard.writeText("brew install monk-io/monk");
    },
  },
};
</script>

<style lang="scss">
.get-monk-modal {
  max-width: 900px !important;

  .modal-content {
    max-height: 90vh;
    overflow: scroll;
    border-radius: 20px;
    background-color: #f8f9ff;

    .modal-header,
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0;
      overflow: scroll;
    }

    .get-monk-modal__content {
      color: #0f141d;

      .close-btn {
        position: absolute;
        top: 25px;
        right: 25px;
        width: 40px;
        height: 40px;
        padding: 0;
        box-sizing: border-box;
        flex-shrink: 0;
        flex-grow: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid black;
        border-radius: 20px;
      }

      .title {
        display: block;
        font-family: Visuelt, sans-serif;
        font-weight: 700;
        font-size: 56px;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
      }

      .title-sec {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 60px;
        letter-spacing: -1px;
        text-align: center;
      }

      .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.1px;
      }

      .preview-block {
        width: 100%;
        height: 235px;
        margin-top: 35px;
        border: 0.5px solid #0f141d;
        box-shadow: 0 10px 0 -5px #0f141d;
        background-color: #fff;

        .script-terminal {
          width: 100%;
          height: 100%;
          padding: 20px;
          background-color: #1b2443;

          code {
            color: #ffffff !important;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}
</style>
