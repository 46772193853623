<template>
  <modal
    v-if="shouldShow"
    @close="onModalCose"
    title="Add new template"
    customDialogClasses="profile-template-modal"
  >
    <template v-slot:body>
      <div class="info-item">
        <TextInput
          label="Name"
          v-model="name"
          type="text"
          :hasError="false"
          :inputWrapper="['mb-3 add-profile-template-input']"
        />
        <TextInput
          label="GitHub URL"
          v-model="link"
          type="text"
          :hasError="false"
          :inputWrapper="['mb-0 add-profile-template-input']"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button
        :active="true"
        @click="onModalCose"
        :isFetching="false"
        addClass="btn-primary"
      >
        <span>Save</span>
      </Button>
      <Button
        :active="true"
        @click="onModalCose"
        :isFetching="false"
        addClass="btn-danger"
      >
        <span>Cancel</span>
      </Button>
    </template>
  </modal>
</template>

<script>
import TextInput from "@/components/ui/TextInput";
import Modal from "@/components/ui/Modal";
import Button from "@/components/ui/Button";

export default {
  components: {
    Modal,
    Button,
    TextInput,
  },
  data() {
    return {
      name: "",
      link: "",
      codeCopied: false,
      runCopied: false,
    };
  },
  computed: {
    shouldShow() {
      return this.$store.getters["modals/getModalState"](
        "ADD_PROFILE_TEMPLATE"
      );
    },
    monccRun() {
      return `monk run `;
    },
  },
  methods: {
    onModalCose() {
      this.$store.dispatch("modals/hideModal", "ADD_PROFILE_TEMPLATE");
    },
    onCopyCode() {
      if (this.codeCopied) return;
      navigator.clipboard.writeText(this.code).then(() => {
        this.codeCopied = true;
        setTimeout(() => {
          this.codeCopied = false;
        }, 1500);
      });
    },
    onCopyRun() {
      if (this.runCopied) return;
      navigator.clipboard.writeText(this.monccRun).then(() => {
        this.runCopied = true;
        setTimeout(() => {
          this.runCopied = false;
        }, 1500);
      });
    },
  },
};
</script>

<style lang="scss">
.profile-template-modal {
  .add-profile-template-input {
    label {
      color: #131a25;
      font-weight: 500;
    }

    .form-control {
      background-color: #fff;
    }
  }
}
</style>
