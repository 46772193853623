import {
  ADD_TEMPLATE,
  REMOVE_TEMPLATE,
  UPDATE_MAIN_INFO,
  UPDATE_COMPONENT_FIELD,
  UPDATE_COMPONENT_VAR,
  RESET,
  ADD_NODE,
  UPDATE_NODE,
  REMOVE_NODE,
  REGION_HINTS,
  ZONE_HINTS,
  INSTANCE_HINTS,
} from "./action-types";
import { fetched, fulfilled, refused } from "@/store/helpers";
import { initialState } from "./index";

export const mutations = {
  [ADD_TEMPLATE]: (state, template) => {
    state.components = [...state.components, template.path];
    state.componentsData = {
      ...state.componentsData,
      [template.path]: {
        name:
          template.path.split("/")[0] +
          "-" +
          (Math.floor(Math.random() * 100) + 1),
        runnable: template.path,
        defines: template.isGroup ? "group" : "runnable",

        // CONNECTION MODAL FIELDS START
        variableTo: "",
        runnableFrom: "",
        containerFrom: "",
        // CONNECTION MODAL FIELDS END

        nodeIndex: -1,

        vars: {},
      },
    };
  },
  [REMOVE_TEMPLATE]: (state, templatePath) => {
    state.components = [...state.components].filter((i) => i !== templatePath);
    const newComponentsData = { ...state.componentsData };
    delete newComponentsData[templatePath];
    state.componentsData = newComponentsData;
  },
  [UPDATE_MAIN_INFO]: (state, { name, value }) => {
    state.mainInfo[name] = value;
  },
  [UPDATE_COMPONENT_FIELD]: (state, { templateName, fieldName, value }) => {
    const newComponentsData = { ...state.componentsData };
    if (newComponentsData[templateName]) {
      newComponentsData[templateName][fieldName] = value;
      state.componentsData = newComponentsData;
    }
  },
  [UPDATE_COMPONENT_VAR]: (state, { templateName, varName, value }) => {
    const newComponentsData = { ...state.componentsData };
    if (newComponentsData[templateName]) {
      newComponentsData[templateName].vars[varName] = {
        name: templateName,
        value,
      };
      state.componentsData = newComponentsData;
    }
  },
  [RESET]: (state) => {
    Object.keys(state).forEach((name) => {
      state[name] = initialState()[name];
    });
  },
  [ADD_NODE]: (state, templateName) => {
    state.nodes = [
      ...state.nodes,
      {
        name: "node-" + (Math.floor(Math.random() * 100) + 1),
        cloud: "gcp",
        instance: "",
        region: "",
        diskSize: "10",
      },
    ];
    const newComponentsData = { ...state.componentsData };
    if (newComponentsData[templateName]) {
      newComponentsData[templateName].nodeIndex = state.nodes.length - 1;
      state.componentsData = newComponentsData;
    }
  },
  [UPDATE_NODE]: (state, { index, fieldName, value }) => {
    const newNodes = [...state.nodes];

    if (newNodes[index] !== undefined) {
      newNodes[index][fieldName] = value;
      state.nodes = newNodes;
    }
  },
  [REMOVE_NODE]: (state, templateName) => {
    const newComponentsData = { ...state.componentsData };

    if (newComponentsData[templateName]) {
      newComponentsData[templateName].nodeIndex = -1;
      state.componentsData = newComponentsData;
    }
  },

  [fetched(REGION_HINTS)]: (state) => {
    state.fetchState.regionHints.isFetching = true;
    state.fetchState.regionHints.hasError = "";
    state.regionHints = [];
  },
  [fulfilled(REGION_HINTS)]: (state, { items }) => {
    state.fetchState.regionHints.isFetching = false;
    state.regionHints = items;
  },
  [refused(REGION_HINTS)]: (state, error) => {
    state.fetchState.regionHints.isFetching = false;
    state.fetchState.regionHints.hasError = error;
    state.regionHints = [];
  },

  [fetched(ZONE_HINTS)]: (state) => {
    state.fetchState.zoneHints.isFetching = true;
    state.fetchState.zoneHints.hasError = "";
    state.zoneHints = [];
  },
  [fulfilled(ZONE_HINTS)]: (state, { items }) => {
    state.fetchState.zoneHints.isFetching = false;
    state.zoneHints = items;
  },
  [refused(ZONE_HINTS)]: (state, error) => {
    state.fetchState.zoneHints.isFetching = false;
    state.fetchState.zoneHints.hasError = error;
    state.zoneHints = [];
  },

  [fetched(INSTANCE_HINTS)]: (state) => {
    state.fetchState.instanceHints.isFetching = true;
    state.fetchState.instanceHints.hasError = "";
    state.instanceHints = [];
  },
  [fulfilled(INSTANCE_HINTS)]: (state, { items }) => {
    state.fetchState.instanceHints.isFetching = false;
    state.instanceHints = items;
  },
  [refused(INSTANCE_HINTS)]: (state, error) => {
    state.fetchState.instanceHints.isFetching = false;
    state.fetchState.instanceHints.hasError = error;
    state.instanceHints = [];
  },
};
