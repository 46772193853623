import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  FETCH_FILTERS,
  SET_FILTER_VALUE,
  SET_QUERY_STRING,
  SET_INITIAL,
} from "./action-types";

export const mutations = {
  [fetched(FETCH_FILTERS)]: (state) => {
    state.fetchState.list = {
      isFetching: true,
      hasError: "",
    };
  },
  [fulfilled(FETCH_FILTERS)]: (state, filters) => {
    const filterCategories = Object.keys(filters);

    if (filterCategories.length) {
      let newFilters = [{ key: "", name: "All", count: "" }];

      filterCategories.forEach((category) => {
        if (category === "templatesCount") {
          newFilters[0].count = filters[category];
        } else if (Array.isArray(filters[category])) {
          newFilters = [
            ...newFilters,
            ...filters[category].map((i) => ({
              ...i,
              name: i.key,
              key: `${category}:${i.key}`,
            })),
          ];
        }
      });

      state.data = [...newFilters];
      state.isReady = true;
    }
  },
  [refused(FETCH_FILTERS)]: (state, hasError) => {
    state.fetchState.list = {
      isFetching: false,
      hasError,
    };
    state.isReady = true;
  },
  [SET_FILTER_VALUE]: (state, value) => {
    state.activeFilter = value;
  },
  [SET_QUERY_STRING]: (state, value) => {
    state.queryString = value;
  },

  [SET_INITIAL]: (state, filters) => {
    Object.keys(filters).forEach((filterName) => {
      if (filterName === "search") {
        state.queryString = filters[filterName];
      } else {
        state.activeFilter = `${filterName}:${filters[filterName]}`;
      }
    });
  },
};
