<template>
  <div class="announce-main">
    <router-link class="mb-5" to="/">
      <img
        class="logo"
        src="@/assets/img/dolphin-full-logo.svg"
        alt="monk logo"
      />
    </router-link>
    <p class="desc mb-3">
      The next 10 years of cloud won’t look like the last. The tools, systems,
      and practices <br />that got here won’t get us there. The cloud needs an
      operating system. <br />Unified. Consistent. Composable.
    </p>
    <h3 class="subtitle">Introducing MonkOS.</h3>
    <span class="choose-text my-4">I want the...</span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.announce-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-top: 120px;
  text-align: center;
  color: #ffffff;

  .logo {
    width: 150px;
    height: auto;
  }

  .title,
  .subtitle {
    color: #63d8cc;
  }

  .desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 30px;
  }

  .subtitle {
    font-weight: 700;
    font-size: 28px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.432407px;
  }

  .choose-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }
}
</style>
