import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  ITEM_DATA,
  FULL_ITEMS_LIST,
  LIST_MODE,
  SET_GROUP_FILTER,
  FULL_ITEM_DATA,
} from "./action-types";

export const mutations = {
  [fetched(ITEM_DATA)]: (state) => {
    state.itemData = {};
    state.fetchState.itemData = {
      isFetching: true,
      hasError: "",
    };
  },
  [fulfilled(ITEM_DATA)]: (state, { id, data }) => {
    state.fetchState.itemData.isFetching = false;
    const newVars = { ...state.variables };
    const newContainers = { ...state.containers };
    const newActions = { ...state.actions };

    if (data.variables) {
      newVars[id] = data.variables;
      state.variables = newVars;
    }

    if (data.containers) {
      newContainers[id] = data.containers;
      state.containers = newContainers;
    }

    if (data.actions) {
      newActions[id] = data.actions;
      state.actions = newActions;
    }
  },
  [refused(ITEM_DATA)]: (state, hasError) => {
    state.fetchState.itemData = {
      isFetching: false,
      hasError,
    };
  },
  [fetched(FULL_ITEMS_LIST)]: (state) => {
    state.fetchState.fullItemsList = {
      isFetching: true,
      hasError: "",
    };
  },
  [fulfilled(FULL_ITEMS_LIST)]: (state, { items, scrollLoading, count }) => {
    state.fetchState.fullItemsList.isFetching = false;
    state.fullItems.list = scrollLoading
      ? [...state.fullItems.list, ...items]
      : items;
    state.fullItems.pagination.start =
      items.length + state.fullItems.pagination.start;
    state.fullItems.pagination.isFull =
      items.length < state.fullItems.pagination.count;
    state.fullItems.foundCount = count;
  },
  [refused(FULL_ITEMS_LIST)]: (state, hasError) => {
    state.fetchState.fullItemsList = {
      isFetching: false,
      hasError,
    };
  },

  [fulfilled(FULL_ITEM_DATA)]: (state, data) => {
    state.fullItemData = { ...state.fullItemData, [data.Path]: { ...data } };
  },

  [LIST_MODE]: (state, mode) => {
    state.listMode = mode;
  },
  [SET_GROUP_FILTER]: (state, mode) => {
    state.groupFilter = mode;
  },
};
