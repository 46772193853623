import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  LOGIN,
  REGISTRATION,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  CLOSE_ACCOUNT,
} from "./action-types";

export const mutations = {
  [fetched(LOGIN)]: (state) => {
    state.login.isFetching = true;
    state.login.hasError = "";
  },
  [fulfilled(LOGIN)]: (state) => {
    state.login.isFetching = false;
    state.registration.isFetching = false;
    state.registration.hasError = "";
  },
  [refused(LOGIN)]: (state, error) => {
    state.login.isFetching = false;
    state.login.hasError = error;
  },
  [fetched(REGISTRATION)]: (state) => {
    state.registration.isFetching = true;
    state.registration.hasError = "";
  },
  [fulfilled(REGISTRATION)]: (state) => {
    state.registration.isFetching = false;
  },
  [refused(REGISTRATION)]: (state, error) => {
    state.registration.isFetching = false;
    state.registration.hasError = error;
  },
  [fetched(RESET_PASSWORD)]: (state) => {
    state.resetPassword.isFetching = true;
    state.resetPassword.hasError = "";
  },
  [fulfilled(RESET_PASSWORD)]: (state) => {
    state.resetPassword.isFetching = false;
  },
  [refused(RESET_PASSWORD)]: (state, error) => {
    state.resetPassword.isFetching = false;
    state.resetPassword.hasError = error;
  },
  [fetched(CHANGE_PASSWORD)]: (state) => {
    state.changePassword.isFetching = true;
    state.changePassword.hasError = "";
  },
  [fulfilled(CHANGE_PASSWORD)]: (state) => {
    state.changePassword.isFetching = false;
  },
  [refused(CHANGE_PASSWORD)]: (state, error) => {
    state.changePassword.isFetching = false;
    state.changePassword.hasError = error;
  },
  [fetched(CLOSE_ACCOUNT)]: (state) => {
    state.closeAccount.isFetching = true;
    state.closeAccount.hasError = "";
  },
  [fulfilled(CLOSE_ACCOUNT)]: (state) => {
    state.closeAccount.isFetching = false;
  },
  [refused(CLOSE_ACCOUNT)]: (state, error) => {
    state.closeAccount.isFetching = false;
    state.closeAccount.hasError = error;
  },
};
