<template>
  <div>
    <KitHeader />
    <BreadCrumbs />
    <KitItemContent />
    <Subscribe />
  </div>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import KitHeader from "@/components/kitHeader";
import KitItemContent from "@/components/kitItem";
import Subscribe from "@/components/main/subscribe";

export default {
  name: "KitItem",
  components: {
    BreadCrumbs,
    KitHeader,
    KitItemContent,
    Subscribe,
  },
};
</script>

<style></style>
