export const ADD_TEMPLATE = "addTemplate";
export const REMOVE_TEMPLATE = "removeTemplate";
export const UPDATE_MAIN_INFO = "updateMainInfo";
export const UPDATE_COMPONENT_FIELD = "updateComponentField";
export const UPDATE_COMPONENT_VAR = "updateComponentVar";
export const RESET = "reset";
export const ADD_NODE = "addNode";
export const UPDATE_NODE = "updateNode";
export const REMOVE_NODE = "removeNode";
export const REGION_HINTS = "regionHints";
export const ZONE_HINTS = "zoneHints";
export const INSTANCE_HINTS = "instanceHints";
