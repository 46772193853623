<template>
  <div class="main__subscribe">
    <div class="container">
      <div class="row">
        <h2 class="subscribe-title col-12">
          Keep up to date <br class="d-md-none" />
          with Monk
        </h2>

        <div class="col-12 d-flex justify-content-center">
          <form
            action="https://oaknode.us7.list-manage.com/subscribe/post?u=1a507ab656237f9c7ec437dc9&amp;id=5e063e86d3"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            class="subscribe-form d-flex flex-row"
            target="_blank"
          >
            <div class="form-group mb-0">
              <input
                type="email"
                value=""
                name="EMAIL"
                class="form-control required email"
                id="mce-EMAIL"
                placeholder="Your email"
                autocomplete="off"
              />
            </div>
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_1a507ab656237f9c7ec437dc9_5e063e86d3"
                tabindex="-1"
                value=""
              />
            </div>
            <button type="submit" class="btn d-flex align-items-center">
              <span class="submit-btn-text">Submit</span>
            </button>
          </form>
        </div>

        <div class="col-12">
          <ul class="page-list row">
            <PageItem v-for="item in pageList" :item="item" :key="item.title" />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageItem from "./PageItem.vue";
export default {
  components: { PageItem },
  data() {
    return {
      pageList: [
        {
          title: "First column",
          pages: [
            { name: "First page" },
            { name: "Second page" },
            { name: "Third" },
            { name: "Fourth" },
          ],
        },
        {
          title: "Second",
          pages: [
            { name: "First page" },
            { name: "Second page" },
            { name: "Third" },
            { name: "Fourth" },
          ],
        },
        {
          title: "Third",
          pages: [
            { name: "First page" },
            { name: "Second page" },
            { name: "Third" },
          ],
        },
        {
          title: "Fourth",
          pages: [
            { name: "First page" },
            { name: "Second page" },
            { name: "Third" },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__subscribe {
  padding-top: 80px;
  padding-bottom: 100px;
  background: #0f141d;

  .subscribe-title {
    margin-bottom: 90px;
    font-weight: 700;
    font-size: 56px;
    line-height: 60px;
    letter-spacing: -0.5px;
    color: #30c9ba;
    text-align: center;
  }
  .subscribe-form {
    width: 100%;
    max-width: 700px;
    margin-bottom: 170px;
    border: 1px solid #30c9ba;

    .form-group {
      flex: 1;
    }

    input.form-control {
      height: 54px;
      padding: 12px 30px;
      border-radius: 0;
      transition: all 0.3s;
      outline: none;
      box-shadow: none;
      font-family: Aeonik, sans-serif;
      border: 1px solid #0f141d;
      font-weight: 250;
      font-size: 20px;
      line-height: 30px;
      color: #63d8cc;
      background-color: #0f141d;

      &::placeholder {
        color: #63d8cc;
      }

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .btn {
      height: 54px;
      padding-left: 20px;
      padding-right: 20px;
      background-color: #63d8cc;
      border-radius: 0;
      font-size: 20px;
      line-height: 30px;
      font-weight: 400;
      color: #1b2443;
      transition: all 0.2s;
      outline: none;
      box-shadow: none;

      &:hover {
        filter: brightness(120%);
      }

      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .page-list {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  @include media-breakpoint-down(md) {
    padding-top: 60px;
    padding-bottom: 40px;

    .subscribe-title {
      margin-bottom: 60px;
      font-size: 34px;
      line-height: 38px;
    }

    .subscribe-form {
      margin-bottom: 50px;
    }
  }
}
</style>
