<template>
  <div>
    <Browse />
    <Run />
    <Encode />
    <Cloud />
    <Resources />
    <Subscribe />
  </div>
</template>

<script>
import Browse from "@/components/main/Browse";
import Run from "@/components/main/Run";
import Encode from "@/components/main/Encode";
import Cloud from "@/components/main/Cloud";
import Resources from "@/components/main/Resources";
import Subscribe from "@/components/main/subscribe";

export default {
  components: {
    Browse,
    Run,
    Encode,
    Cloud,
    Resources,
    Subscribe,
  },
};
</script>

<style></style>
