var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'form-group custom-input-wrapper',
    _vm.hasError && 'has-danger',
    ..._vm.inputWrapper,
  ]},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('input',{class:[
      'form-control',
      _vm.hasError && 'form-control-danger valid',
      _vm.disabled && 'disabled',
    ],attrs:{"type":_vm.type,"name":_vm.name || '',"placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"input":_vm.handleInput,"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.submit.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.onKeyUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;return _vm.onKeyDown.apply(null, arguments)}],"blur":_vm.onBlur,"focus":_vm.onFocus}}),(_vm.showCleaner && _vm.value && _vm.value.length)?_c('i',{staticClass:"mdi mdi-close textinput-cleaner",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClean.apply(null, arguments)}}}):_vm._e(),((_vm.showHint || _vm.focused) && _vm.hints && _vm.computedList.length)?_c('div',{ref:"inputHintWrapper",staticClass:"hints-wrapper",style:(_vm.label ? 'top: 69px;' : '')},_vm._l((_vm.computedList),function(hint,index){return _c('span',{key:hint + index,staticClass:"hint-item",class:index === _vm.selectedHint && 'hint-selected',on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onHintPress(hint)}}},[_vm._v(" "+_vm._s(hint)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }