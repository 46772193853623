<template>
  <div class="devops-banner">
    <div class="devops-banner__content">
      <span class="devops-banner__title">DevOps for the modern Age</span>
      <p class="mb-0 mt-4 devops-banner__desc">
        Separated they live in Bookmarksgrove right at the coast of the famous
        Semantics, large language ocean and many more stuff and more more more.
      </p>
      <div
        class="d-flex align-items-center justify-content-start flex-row mt-4"
      >
        <a href="#" class="simple-btn simple-shadow">
          <span>Main CTA</span>
        </a>
        <a href="#" class="simple-link ml-4 d-flex flex-row black">
          <span>Secondary CTA</span>
          <img
            src="@/assets/icons/simple-arrow_black.svg"
            alt="arrow icon"
            class="simple-icon black ml-2 mt-1"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DevopsBanner",
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.devops-banner {
  margin-bottom: 20px;
  padding: 45px;
  background-color: #e8f9f7;
  box-shadow: 0 20px 0 -10px #dbdfed;
  background-image: url(~@/assets/img/devops-banner-bg.png);
  background-position: center right 70px;
  background-size: auto 120%;
  background-repeat: no-repeat;

  .devops-banner__content {
    max-width: 450px;
  }

  .devops-banner__title {
    font-family: Visuelt, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: #0f141d;
  }

  .devops-banner__desc {
    font-weight: 250;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
  }

  @include media-breakpoint-down(md) {
    padding: 25px;
    background-position: center right 10px;
    background-size: auto 100%;

    .devops-banner__content {
      max-width: 400px;
    }
  }

  @include media-breakpoint-down(sm) {
    padding: 20px;
    padding-top: 380px;
    background-position: top 10px center;
    background-size: auto 370px;

    .devops-banner__content {
      max-width: 100%;
    }
  }
}
</style>
