import axios from "axios";
import { path } from "ramda";

import router from "@/router";

const BASE_URL = process.env.VUE_APP_USER_API_URL;

class Client {
  constructor() {
    this.prepareClient();
  }

  storedToken() {
    return JSON.parse(window.localStorage.getItem("token"));
  }

  storedRefreshToken() {
    return JSON.parse(window.localStorage.getItem("refresh-token"));
  }

  storeToken(token) {
    window.localStorage.setItem("token", JSON.stringify(token.accessToken));

    if (token.refreshToken) {
      window.localStorage.setItem(
        "refresh-token",
        JSON.stringify(token.refreshToken)
      );
    }
  }

  prepareClient() {
    const opts = {
      baseURL: BASE_URL,
      validateStatus: (status) => status !== 403 && status !== 401, // will start error handler in interceptors.response only for 403 status
    };
    this.axios = axios.create(opts);

    this.axios.interceptors.request.use((config) => {
      const savedToken = this.storedToken();
      const noAuthToken = ["user/login", "user/register", "user/token/refresh"];
      let originalRequest = config;

      if (savedToken && !noAuthToken.includes(config.url)) {
        originalRequest.headers["Authorization"] = `Bearer ${savedToken}`;
        return Promise.resolve(originalRequest);
      }

      return config;
    });

    this.axios.interceptors.response.use(
      (res) => res,
      async (error) => {
        const status = path(["response", "status"], error);
        const originalRequest = error.config;

        if (
          (status === 403 || status === 401) &&
          originalRequest.url === "user/token/refresh"
        ) {
          this.logout();
          return Promise.reject(error.response);
        }

        if ((status === 403 || status === 401) && !originalRequest._retry) {
          originalRequest._retry = true;
          const refreshResult = await this.refreshToken();

          if (refreshResult) {
            if (originalRequest && error.response) {
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${refreshResult}`;

              if (originalRequest.data) {
                originalRequest.data = JSON.parse(originalRequest.data);
              }
              const newResp = await axios.request(originalRequest);

              return newResp;
            }

            return true;
          }

          this.logout();
        }

        return Promise.resolve(error.response);
      }
    );
  }

  def(method, path, fields) {
    return async (payload) => {
      const body = {};
      if (fields) {
        for (const field of fields) {
          body[field] = payload[field];
        }
      }
      try {
        this.trace(method, path, body);
        const response = await this.axios[method](path, body);
        if (response.data) {
          if (response.data.data) {
            // success
            return response.data.data;
          } else if (response.data.error) {
            // backend returned an error
            throw ["backend error", response.data.error];
          }
        } else {
          // server did not return a body
          throw ["empty response", response.data.error];
        }
      } catch (e) {
        this.error(method, path, body, e);
        return null;
      }
    };
  }

  error(...e) {
    console.log("http error", ...e); // eslint-disable-line no-console
  }

  trace(...e) {
    console.log("http call", ...e); // eslint-disable-line no-console
  }

  async login({ email, password }) {
    try {
      const res = await this.axios.post("user/login", {
        email,
        password,
      });

      if (res.data.data) {
        const data = res.data.data;

        this.storeToken(data);
        return true;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("login", e);
      return { ...e[1].data };
    }
  }

  async register({ email, password }) {
    try {
      const res = await this.axios.post("user/register", {
        email,
        password,
      });

      if (res.data.data) {
        return true;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("registration", e);
      return { ...e[1].data };
    }
  }

  async resetPassword({ email }) {
    try {
      const res = await this.axios.post("user/email/reset", { email });

      if (res.data.data) {
        return true;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("reset password", e);
      return { ...e[1].data };
    }
  }

  async closeAccount() {
    try {
      const res = await this.axios.delete("user/close-account");

      if (res.data.data) {
        return true;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("close account", e);
      return { ...e[1].data };
    }
  }

  async changePassword() {
    try {
      const res = await this.axios.put("user/password/change");

      if (res.data.data) {
        return true;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("reset password", e);
      return { ...e[1].data };
    }
  }

  async refreshToken() {
    const storedRefreshToken = this.storedRefreshToken();

    if (!storedRefreshToken) {
      return false;
    }

    return await this.axios
      .post("user/token/refresh", {
        refreshToken: storedRefreshToken,
      })
      .then((refreshTokenData) => {
        if (refreshTokenData.data.data) {
          this.storeToken(refreshTokenData.data.data);

          return refreshTokenData.data.data.accessToken;
        }

        return false;
      });
  }

  async revokeToken() {
    try {
      let refreshToken = this.storedRefreshToken();

      if (!refreshToken) {
        throw ["there is no refresh token"];
      }
      const res = await this.axios.post("user/token/revoke", {
        refreshToken,
      });

      if (res.data.data) {
        return false;
      } else {
        throw ["empty resp", res];
      }
    } catch (e) {
      this.error("revoke token", e);
      return false;
    }
  }

  async logout() {
    try {
      await this.revokeToken();
      if (router.currentRoute.name === "profile")
        router.replace({ name: "login" });

      window.localStorage.removeItem("token");
      window.localStorage.removeItem("refresh-token");

      return true;
    } catch (e) {
      this.error(e);
      return false;
    }
  }

  async user() {
    try {
      const res = await this.axios.get("user/info");

      if (res.data.data) {
        return res.data.data;
      } else {
        throw ["empty response", res];
      }
    } catch (e) {
      this.error("user", e);
      return null;
    }
  }
}

const userClient = new Client();

export default userClient;
