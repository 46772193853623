<template>
  <div>
    <Deploy />
    <DeploySimple />
    <AddProfileTemplate />
    <GetMonk />
  </div>
</template>

<script>
import Deploy from "./Deploy";
import DeploySimple from "./DeploySimple";
import AddProfileTemplate from "./AddProfileTemplate";
import GetMonk from "./GetMonk";

export default {
  name: "modals",
  components: {
    Deploy,
    DeploySimple,
    AddProfileTemplate,
    GetMonk,
  },
};
</script>
