<template>
  <modal
    v-if="shouldShow"
    @close="onModalCose"
    :customDialogClasses="['deploy-modal']"
  >
    <template v-slot:body>
      <button class="btn close-btn" @click="onModalCose">
        <img src="@/assets/icons/close-pixeled.svg" alt="close icon" />
      </button>
      <span class="title mb-4">Deployment</span>
      <div v-if="!info.isGroup" class="info-item mb-4">
        <span class="into-item_title">Inherit in your stack</span>
        <div class="my-2 console-code-preview card">
          <pre class="mb-0 p-0 py-2">{{ code }}</pre>
          <button
            class="btn simple-btn white size-xs mt-2 ml-auto"
            @click="onCopyRun"
          >
            <span>{{ codeCopied ? "Copied!" : "Copy" }}</span>
          </button>
        </div>
      </div>
      <div class="info-item mb-4">
        <span class="into-item_title">Instant deploy</span>
        <div class="my-2 console-code-preview card">
          <pre class="mb-0 p-0 py-2">{{ monkRun }}</pre>
          <button
            class="btn simple-btn white size-xs mt-2 ml-auto"
            @click="onCopyRun"
          >
            <span>{{ runCopied ? "Copied!" : "Copy" }}</span>
          </button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/ui/Modal";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      codeCopied: false,
      runCopied: false,
    };
  },
  computed: {
    shouldShow() {
      return this.$store.getters["modals/getModalState"](
        "TEMPLATE_DEPLOY_SIMPLE"
      );
    },
    info() {
      return this.$store.getters["templates/templateItemData"](this.pathName);
    },
    pathName() {
      return this.$route.query.template;
    },
    code() {
      return `namespace: your_namespace\n\n${this.info.Path.replace(
        "/",
        "-"
      )}-1\n  defines: runnable\n  inherits: ${this.info.Path}`;
    },
    monkRun() {
      return `monk run ${this.info.Path}`;
    },
  },
  methods: {
    onModalCose() {
      this.$router.replace(
        {
          name: this.$route.name,
          query: {
            ...this.$route.query,
            template: [],
          },
        },
        () => this.$store.dispatch("modals/hideModal", "TEMPLATE_DEPLOY_SIMPLE")
      );
    },
    onCopyCode() {
      if (this.codeCopied) return;
      navigator.clipboard.writeText(this.code).then(() => {
        this.codeCopied = true;
        setTimeout(() => {
          this.codeCopied = false;
        }, 1500);
      });
    },
    onCopyRun() {
      if (this.runCopied) return;
      navigator.clipboard.writeText(this.monkRun).then(() => {
        this.runCopied = true;
        setTimeout(() => {
          this.runCopied = false;
        }, 1500);
      });
    },
  },
};
</script>

<style lang="scss">
.deploy-modal {
  .modal-content {
    max-height: 90vh;
    overflow: scroll;
    border-radius: 20px;
    background-color: #f8f9ff;

    .modal-header,
    .modal-footer {
      display: none;
    }

    .modal-body {
      padding: 0;
      overflow: scroll;
    }
  }

  .close-btn {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    padding: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    border-radius: 20px;
  }

  .title {
    display: block;
    font-family: Visuelt, sans-serif;
    font-weight: 700;
    font-size: 35px;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: center;
  }

  .into-item_title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bolder;
    color: #0f141d;
  }

  .console-code-preview {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 0.5rem;
    background-color: #1b2443;
    border: 0.5px solid #0f141d;
    box-shadow: 0 10px 0 -5px #0f141d;

    pre {
      background-color: inherit;
      font-family: "Lineto", Courier New, monospace;
      color: #fff;
      font-size: 15px;
    }
  }
}
</style>
