<template>
  <modal
    v-if="shouldShow"
    @close="onModalCose"
    title="Deployment"
    customDialogClasses="deploy-modal"
  >
    <template v-slot:body>
      <div class="info-item">
        <span class="into-item_title text-primary">Instant deploy</span>
        <div class="my-2 console-code-preview card">
          <pre class="mb-0 p-0 py-2">{{ monccRun }}</pre>
          <Button
            :active="true"
            @click="onCopyRun"
            addClass="btn-xs btn-primary mt-2 ml-auto"
            :isFetching="false"
          >
            {{ runCopied ? "Copied!" : "Copy" }}
          </Button>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/ui/Modal";
import Button from "@/components/ui/Button";

export default {
  components: {
    Modal,
    Button,
  },
  data() {
    return {
      codeCopied: false,
      runCopied: false,
    };
  },
  computed: {
    shouldShow() {
      return this.$store.getters["modals/getModalState"]("TEMPLATE_DEPLOY");
    },
    info() {
      return this.$store.getters["templates/templateItemData"](this.pathName);
    },
    pathName() {
      return this.$route.query.template;
    },
    code() {
      return `${this.info.path}-1\n  defines: runnable\n  inherits: ${this.info.path}`;
    },
    monccRun() {
      return `monk run ${this.namespace}/${this.systemName}`;
    },
    namespace() {
      return this.$store.getters["cart/getMainInfoItemValue"](
        "namespace"
      ).replace("/", "");
    },
    systemName() {
      return this.$store.getters["cart/getMainInfoItemValue"]("systemName");
    },
  },
  methods: {
    onModalCose() {
      this.$store.dispatch("modals/hideModal", "TEMPLATE_DEPLOY");
    },
    onCopyCode() {
      if (this.codeCopied) return;
      navigator.clipboard.writeText(this.code).then(() => {
        this.codeCopied = true;
        setTimeout(() => {
          this.codeCopied = false;
        }, 1500);
      });
    },
    onCopyRun() {
      if (this.runCopied) return;
      navigator.clipboard.writeText(this.monccRun).then(() => {
        this.runCopied = true;
        setTimeout(() => {
          this.runCopied = false;
        }, 1500);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.deploy-modal {
  .into-item_title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bolder;
  }

  .console-code-preview {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-bottom: 0.5rem;

    pre {
      background-color: inherit;
      font-family: "Lineto", Courier New, monospace;
      color: #f9452d;
      font-size: 15px;
    }
  }
}
</style>
