<template>
  <div class="col-12 col-lg-3 explore-kit-filters">
    <span class="title">Filter</span>

    <div v-for="filter in filtersList" :key="filter.name" class="filter-item">
      <input
        type="radio"
        :id="`explore-kit-filter-${filter.key}`"
        :value="filter.key"
        :checked="activeFilter === filter.key"
        @change="setActiveFilter"
      />
      <label :for="`explore-kit-filter-${filter.key}`">
        <span class="text">{{ filter.name }}</span>
        <span v-show="filter.count !== ''" class="counter">{{
          filter.count || "0"
        }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "KitsFilters",
  data() {
    return {
      picked: "all",
    };
  },
  mounted() {
    this.setInitialFilters();

    if (this.isInitialStateReady && !this.querySearchValue) {
      this.fetchAllData();
    } else {
      this.fetchFilters();
    }
  },
  computed: {
    querySearchValue() {
      return this.$route.query.search || "";
    },
    isInitialStateReady() {
      return this.$store.getters["filters/getReadyState"];
    },
    filtersList() {
      return this.$store.getters["filters/getFilters"];
    },
    activeFilter() {
      return this.$store.getters["filters/getActiveFilter"];
    },
  },
  methods: {
    fetchAllData() {
      this.$store.dispatch("templates/fetchFullItemsList", {
        from: 0,
      });
    },
    setInitialFilters() {
      this.$store.dispatch("filters/setInitialFilters", this.$route.query);
    },
    fetchFilters() {
      this.$store.dispatch("filters/fetchFilters");
    },
    setActiveFilter(e) {
      this.$store.dispatch("filters/setFilterValue", e.target.value);
    },
    setRouteParams(filter) {
      const queryArray = filter.split(":");
      const query = filter ? { [queryArray[0]]: queryArray[1] } : {};

      return this.$router
        .push({
          name: this.$route.name,
          query: { search: this.$route.query.search || [], ...query },
        })
        .catch(() => true);
    },
  },
  watch: {
    isInitialStateReady(newVal, oldVal) {
      if (newVal && !oldVal && !this.querySearchValue) {
        this.fetchAllData();
      }
    },
    activeFilter(newVal) {
      this.setRouteParams(newVal);
      this.fetchAllData();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap-grid";

.explore-kit-filters {
  position: sticky !important;
  z-index: 100;
  top: 170px;
  background-color: #f8f9ff;
  height: max-content;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  max-height: calc(100vh - 200px);

  overflow: scroll;
  &::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */

  .title {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 2px;
    color: #94a3b8;
    text-transform: uppercase;
  }
  .filter-item {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-shrink: 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:hover ~ label {
        color: #2e343d;
        font-weight: 500;
      }

      &:checked ~ label {
        color: #2e343d;
        font-weight: 500;

        &:before {
          display: block;
        }
      }
    }

    label {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #848897;
      cursor: pointer;

      &:before {
        display: none;
        content: "";
        position: absolute;
        width: 3px;
        height: 100%;
        left: -15px;
        top: 0;
        background-color: blue;
      }

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }

      .counter {
        display: block;
        margin-left: auto;
        padding: 0px 6px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #0315f9;
        background-color: #ebecfe;
        border-radius: 4px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    max-height: unset;
    flex-direction: row;
    height: 45px;
    top: 150px;

    .title {
      display: none;
    }

    .filter-item label {
      width: auto;
      margin-right: 30px;

      &:before {
        top: calc(100% + 3px);
        left: 0;
        height: 3px;
        width: 100%;
      }

      .counter {
        display: none;
      }
    }
  }
}
</style>
