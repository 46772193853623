import { initialFetchState } from "@/store/helpers";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const initialState = () => ({
  data: [{ key: "", name: "All", count: "" }],
  queryString: "",
  activeFilter: "",
  isReady: false,
  fetchState: {
    list: initialFetchState(),
  },
});

const filters = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default filters;
