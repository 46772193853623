export const getters = {
  listMode: (state) => state.listMode,
  itemData: (state) => state.itemData,
  itemsListWithGroupFilter: (state) => {
    if (!state.groupFilter.length || state.groupFilter.length === 2)
      return state.fullItems.list;
    if (state.groupFilter[0] === "runnable")
      return state.fullItems.list.filter((i) => !i.isGroup);
    return state.fullItems.list.filter((i) => i.isGroup);
  },
  fullItemsList: (state) => state.fullItems.list,
  templateItemData:
    (state) =>
    (path = "") => {
      const list = state.fullItems.list;

      if (!path) return { path };

      if (state.fullItemData[path]) return state.fullItemData[path];

      if (!path || !list || !list.length) return { path };

      const result = list.find((i) => i.Path === path);

      return result && result.Path ? result : { Path: path };
    },

  itemDataFetchState: (state) => state.fetchState.itemData,
  fullItemsListFetchState: (state) => state.fetchState.fullItemsList,

  itemActions: (state) => (id) => state.actions[id] || {},
  itemVariables: (state) => (id) => state.variables[id] || {},
  itemContainers: (state) => (id) => state.containers[id] || {},
  groupFilter: (state) => state.groupFilter,
  foundCount: (state) => state.fullItems.foundCount,
};
