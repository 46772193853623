<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal show modal-wrapper" @click.self="close">
        <div :class="['modal-dialog', ...customDialogClasses]" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <div class="d-flex flex-row align-items-center">
                <h5 v-if="title" class="modal-title">
                  {{ title }}
                </h5>
                <CircleLoader v-if="showLoader" :scale="0.4" />
              </div>
            </div>
            <div class="modal-body">
              <slot name="body" />
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <Button
                  :active="true"
                  @click="close"
                  addClass="modal-cancel-btn"
                  :isFetching="false"
                >
                  <span>Cancel</span>
                </Button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CircleLoader from "@/components/ui/CircleLoader";
import Button from "@/components/ui/Button";

export default {
  name: "Modal",
  components: { CircleLoader, Button },
  props: {
    title: String,
    showLoader: Boolean,
    customDialogClasses: [Array, String],
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.modal-mask {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  transition: opacity 0.3s ease;
  .modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0;

    &.modal .modal-dialog {
      flex: 1;
      margin-top: 0 !important;
    }
  }
}

.modal-header {
  background-color: #0315f9;
}

.modal-cancel-btn {
  padding: 0 !important;
  width: 95px;
  height: 45px;
  background-color: #fff;
  border: none !important;
  border-left: 1px solid #d3d2d1 !important;
  text-transform: lowercase;
  position: relative;
  overflow: hidden;

  span {
    z-index: 10;
    color: #f9452d;
    transition: all 0.15s;
    position: relative;
  }

  &:before {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f9452d;
    bottom: -100%;
    z-index: 5;
    transition: all 0.3s;
  }

  &:hover {
    span {
      color: #fff;
    }

    &:before {
      bottom: 0;
    }
  }
}

/*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
