import { initialFetchState } from "@/store/helpers";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const initialState = () => ({
  login: initialFetchState(),
  registration: initialFetchState(),
  resetPassword: initialFetchState(),
  changePassword: initialFetchState(),
  closeAccount: initialFetchState(),
});

const auth = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default auth;
