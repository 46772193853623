<template>
  <div class="main__cloud">
    <div class="container">
      <div class="row d-flex flex-column-reverse flex-lg-row">
        <div
          class="col-12 col-sm-6 col-md-6 col-lg-5 d-flex flex-column align-items-start"
        >
          <h4 class="title">Your cloud or ours, unlimited portability</h4>
          <p class="desc my-4">
            Separated they live in Bookmarks right at the coast of the famous
            Semantics, large language ocean Separated they live in Bookmarks
            right at the coast
          </p>
          <div class="d-flex flex-column align-items-start mt-2 mb-5">
            <div class="mb-2 cloud-item">
              <span>On your own infrastructure</span>
            </div>
            <div class="mb-2 cloud-item">
              <span>On public clouds</span>
              <img
                src="@/assets/icons/gcp.svg"
                alt="gcp icon"
                class="simple-icon white ml-1"
              />
              <img
                src="@/assets/icons/azure.svg"
                alt="azure icon"
                class="simple-icon white ml-1"
              />
              <img
                src="@/assets/icons/aws.svg"
                alt="aws icon"
                class="simple-icon white ml-1"
              />
            </div>
            <div class="mb-2 cloud-item">
              <span>Monk Cloud</span>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-start flex-row mt-3"
          >
            <a href="#" class="simple-btn">
              <span>Run Now</span>
            </a>
            <a
              href="#"
              class="simple-link text-decoration-none ml-4 d-flex flex-row"
            >
              <span>Join waiting list</span>
              <img
                src="@/assets/icons/simple-arrow_black.svg"
                alt="arrow icon"
                class="simple-icon white ml-2 mt-1"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__cloud {
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
  padding: 140px 0;
  background-color: #0f141d;
  color: #f8f9ff;
  position: relative;
  background-image: url(~@/assets/img/main/main__cloud.png);
  background-position: right center;
  background-size: auto 99%;
  background-repeat: no-repeat;

  .title {
    line-height: 52px;
    color: #63d8cc;
  }

  .desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 30px;
  }

  .cloud-item {
    display: flex;
    flex-direction: row;
    padding-left: 32px;
    background: url(~@/assets/icons/check-bordered-aqua.svg) left top / 18px
      18px no-repeat;
  }

  @include media-breakpoint-down(lg) {
    padding: 45px 0;
    background-size: 55% auto;
    min-height: auto;

    .title {
      line-height: 104%;
    }
  }

  @include media-breakpoint-down(md) {
    overflow: hidden;
    background-size: 45% auto;
  }

  @include media-breakpoint-down(xs) {
    padding-top: 440px;
    background-size: auto 400px;
    background-position: right top;

    .illustration {
      top: -40px;
      right: 0;
      max-width: 100%;
      max-height: 400px;
    }
  }
}
</style>
