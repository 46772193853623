<template>
  <div id="explore-infinite-list" class="col grid-margin">
    <div class="row">
      <KitItem v-for="item in items" :data="item" :key="item.id" />
    </div>
    <div
      v-if="isFiltersInitialStateReady && !items.length && !isFetching"
      class="d-flex flex-column align-items-center justify-content-center pt-3 pt-md-0"
    >
      <img
        src="@/assets/img/empty-search-img.png"
        alt="empty search illustration"
        class="empty-search-img"
      />
      <span class="empty-search-title mt-4 mb-3">No results found</span>
      <span class="empty-search-desc"
        >We couldn’t find what you’re looking for</span
      >
    </div>
    <div
      v-if="isFetching || !isFiltersInitialStateReady"
      class="dot-opacity-loader"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
</template>

<script>
import KitItem from "./KitItem";

export default {
  name: "TemplateList",
  components: {
    KitItem,
  },
  data() {
    return {
      isUpdating: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    isFetching() {
      return this.$store.getters["templates/fullItemsListFetchState"]
        .isFetching;
    },
    items() {
      return this.$store.getters["templates/itemsListWithGroupFilter"];
    },
    isFiltersInitialStateReady() {
      return this.$store.getters["filters/getReadyState"];
    },
  },
  methods: {
    handleScroll() {
      const listElm = document.querySelector("#explore-infinite-list");

      if (
        window.pageYOffset + 1500 >= listElm.scrollHeight &&
        this.items.length
      ) {
        this.updateData();
      }
    },
    updateData() {
      if (this.isUpdating || !this.items.length) return;

      this.isUpdating = true;
      this.fetchData();
      setTimeout(() => (this.isUpdating = false), 700);
    },
    fetchData() {
      this.$store.dispatch("templates/fetchFullItemsList", {
        scrollLoading: true,
      });
    },
  },
};
</script>

<style lang="scss">
.empty-search-img {
  width: 280px;
  max-width: 100%;
}
.empty-search-title {
  font-weight: 700;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: -0.432407px;
  color: #0f141d;
}
.empty-search-desc {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #0f141d;
}
</style>
