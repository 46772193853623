import Vue from "vue";
import VueRouter from "vue-router";

import Main from "../views/Main";
import Explore from "../views/Explore";
import KitItem from "../views/KitItem";
import Announce from "../views/announce";
// import Search from "../views/Search";

// import Cart from "../views/Cart";
// import Home from "../views/Home";
// import Login from "../views/Login";
// import Register from "../views/Register";
// import ForgotPassword from "../views/ForgotPassword";
// import Profile from "../views/Profile";
// import Settings from "../views/Settings";
// import Rewards from "../views/Rewards";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Announce,
  },
  // {
  //   path: "/",
  //   name: "home",
  //   component: Main,
  // },
  // {
  //   path: "/explore",
  //   name: "explore",
  //   component: Explore,
  // },
  // {
  //   path: "/kit-item/:id",
  //   name: "kit-item",
  //   component: KitItem,
  // },
  {
    path: "*",
    redirect: { name: "home" },
  },

  // OLD :
  // { path: "/login", name: "login", component: Login },
  // { path: "/register", name: "register", component: Register },
  // { path: "/restore", name: "restore", component: ForgotPassword },
  // {
  //   path: "/profile",
  //   name: "profile",
  //   component: Profile,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/settings",
  //   name: "settings",
  //   component: Settings,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/rewards",
  //   name: "rewards",
  //   component: Rewards,
  //   meta: { requiresAuth: true },
  // },
  // {
  //   path: "/main",
  //   name: "main",
  //   component: Main,
  // },
  // {
  //   path: "/search",
  //   name: "search",
  //   component: Search,
  // },
  // {
  //   path: "/compose",
  //   name: "compose",
  //   component: Cart,
  // },
  // {
  //   path: "/billing",
  //   name: "billing",
  //   component: Billing,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!savedPosition || to.name === "kit-item") return { y: 0 };

    return savedPosition;
  },
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     const token = localStorage.getItem("token");
//     if (token == null || !token) {
//       next({
//         name: "login",
//         params: { next: to.fullPath },
//       });
//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
