<template>
  <div class="main__resources">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-md-9">
          <h4 class="title text-center mb-3">Dive deeper into building</h4>
          <p class="desc text-center">
            From in-depth documentation and resources to a growing community or
            <br />like-minded devs, Monk helps you build and deploy with ease
            every step of the way.
          </p>
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <div
          v-for="(i, index) in items"
          :key="index"
          class="col-md-10 col-lg-4 mb-5 mb-lg-0 resource-item"
        >
          <div class="resource-item-content">
            <div class="resource-item-header"></div>
            <div
              class="resource-item-body d-flex flex-column align-items-start px-4 pt-3 pb-4"
            >
              <span class="resource-name">
                {{ i.title }}
              </span>
              <p class="my-2">{{ i.desc }}</p>
              <a :href="i.link" class="simple-link black d-flex flex-row">
                <span>Explore</span>
                <img
                  src="@/assets/icons/simple-arrow_black.svg"
                  alt="arrow icon"
                  class="simple-icon ml-2 mt-1"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "Documentation",
          desc: "Separated they live in Bookmarks grove right at the coast of the famous Semantics, large language",
          link: "#",
        },
        {
          title: "Resources",
          desc: "Separated they live in Bookmarks grove right at the coast of the famous Semantics, large language",
          link: "#",
        },
        {
          title: "Community",
          desc: "Separated they live in Bookmarks grove right at the coast of the famous Semantics, large language",
          link: "#",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__resources {
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
  padding-top: 125px;
  padding-bottom: 75px;
  background-color: #e9ecf3;

  p.desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 28px;
  }

  .resource-item {
    font-weight: 250;
    font-size: 16px;
    line-height: 24px;

    .resource-name {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }

    .resource-item-content {
      background-color: #eef1f8;
      border: 0.7px solid #0f141d;
      box-shadow: 0 20px 0 -10px rgba(15, 20, 29, 0.7);
    }

    .resource-item-header {
      height: 205px;
      border-bottom: 0.7px solid #0f141d;
      background-color: #99e0d9;
      background-image: url(~@/assets/img/main/get-monk__doc.png);
      background-position: center 23px;
      background-size: auto 70%;
      background-repeat: no-repeat;
    }

    &:nth-child(2) {
      .resource-item-header {
        background-image: url(~@/assets/img/main/get-monk__res.png);
        background-color: #8089ff;
      }
    }

    &:nth-child(3) {
      .resource-item-header {
        background-image: url(~@/assets/img/main/get-monk__com.png);
        background-color: #fecb17;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    padding-top: 60px;
    padding-bottom: 60px;
    min-height: auto;
  }
}
</style>
