<template>
  <div class="main__page-item col-6 col-lg-3 d-flex justify-content-center">
    <div>
      <h4 class="item-title">{{ item.title }}</h4>
      <div v-for="page in item.pages" :key="page.name">
        <span class="item-name">{{ page.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageItem",
  props: ["item"],
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__page-item {
  .item-title {
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 20px;
    line-height: 37px;
  }

  .item-name {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 31px;
    color: #575c6d;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:nth-child(1) {
    .item-title {
      color: #acb2ff;
    }
  }

  &:nth-child(2) {
    .item-title {
      color: #63d8cc;
    }
  }

  &:nth-child(3) {
    .item-title {
      color: #ff9b8d;
    }
  }

  &:nth-child(4) {
    .item-title {
      color: #ffefb7;
    }
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;

    .item-title {
      margin-bottom: 15px;
    }
  }
}
</style>
