export const getters = {
  components: (state) => state.components,
  cartItemsTotal: (state) => state.components.length,
  isTemplateInCart: (state) => (templateName) =>
    state.components.some((i) => i === templateName),
  getMainInfoData: (state) => state.mainInfo,
  getMainInfoItemValue: (state) => (fieldName) => state.mainInfo[fieldName],
  getComponentsData: (state) => state.componentsData,
  getComponentsDataTemplate: (state) => (templateName) =>
    state.componentsData[templateName],
  getComponentsDataTemplateField:
    (state) =>
    ({ templateName, fieldName }) =>
      state.componentsData[templateName]
        ? state.componentsData[templateName][fieldName]
        : "",
  getComponentVars: (state) => (templateName) =>
    Object.keys(state.componentsData[templateName].vars),
  getComponentVarsFull: (state) => (templateName) =>
    state.componentsData[templateName].vars
      ? { ...state.componentsData[templateName].vars }
      : {},
  getComponentVarValue:
    (state) =>
    ({ templateName, varName }) =>
      state.componentsData[templateName] &&
      state.componentsData[templateName].vars[varName]
        ? state.componentsData[templateName].vars[varName].value
        : "",
  getNodesList: (state) => state.nodes,
  getNodeItemFieldValue:
    (state) =>
    ({ index, fieldName }) =>
      index === -1 && fieldName === "cloud"
        ? ""
        : state.nodes[index][fieldName],
  regionHints: (state) => state.regionHints,
  zoneHints: (state) => state.zoneHints,
  instanceHints: (state) => state.instanceHints,
};
