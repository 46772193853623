import api from "@/api/userClient";
import {
  fetched,
  fulfilled,
  refused,
  initialFetchState,
} from "@/store/helpers";
import { SET, REMOVE, USER } from "./action-types";

const user = {
  namespaced: true,
  state: {
    user: {
      name: "",
      email: "",
    },
    fetchState: {
      user: initialFetchState(),
    },
  },
  getters: {
    user: (state) => state.user,
    userFetchState: (state) => state.fetchState.user,
  },
  mutations: {
    [SET]: (state, user) => (state.user = user),
    [REMOVE]: (state) => (state.user = { name: "", email: "", user_id: "" }),
    [fetched(USER)]: (state) => {
      state.fetchState.user.isFetching = true;
      state.fetchState.user.hasError = "";
    },
    [fulfilled(USER)]: (state) => (state.fetchState.user.isFetching = false),
    [refused(USER)]: (state, error) => {
      state.fetchState.user.isFetching = false;
      state.fetchState.user.hasError = error;
    },
  },
  actions: {
    async fetchUser({ commit, state }, forceFetch) {
      if (forceFetch || !state.user || !state.user.name) {
        commit(fetched(USER));

        const resp = await api.user();

        if (typeof resp === "object") {
          commit(SET, resp);
          commit(fulfilled(USER));
        } else {
          const error = resp.error ? resp.error : "";

          commit(refused(USER), error);
        }
      }
    },
    remove({ commit }) {
      commit(REMOVE);
    },
  },
};

export default user;
