import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import createPersistedState from "vuex-persistedstate";

import templates from "./templates";
import filters from "./filters";
import user from "./user";
import auth from "./auth";
import modals from "./modals";
import cart from "./cart";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";
const storeStatePlugin = createPersistedState({
  paths: [
    "cart",
    "templates.actions",
    "templates.variables",
    "templates.containers",
  ],
});

export default new Vuex.Store({
  modules: {
    templates,
    filters,
    user,
    auth,
    modals,
    cart,
  },
  state: {},
  mutations: {
    saved: (state) => {
      state.saved = true;
    },
  },
  getters: {},
  actions: {},

  /**
   * If strict mode should be enabled.
   */
  strict: debug,
  devtools: debug,

  /**
   * Plugins used in the store.
   */
  plugins: debug ? [createLogger(), storeStatePlugin] : [storeStatePlugin],
});
