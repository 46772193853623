import axios from "axios";

const BASE_URL = process.env.VUE_APP_BASE_URL;

class Client {
  constructor() {
    this.prepareClient();
  }

  prepareClient() {
    const opts = {
      baseURL: BASE_URL,
    };
    this.axios = axios.create(opts);

    this.axios.interceptors.response.use(
      (res) => res,
      async (error) => Promise.resolve(error.response)
    );
  }

  error(...e) {
    console.log("http error", ...e); // eslint-disable-line no-console
  }

  async fetchFilters() {
    try {
      const res = await this.axios.get("v1/aggs");

      if (res && res.data) {
        return res.data;
      } else {
        throw ["empty resp", res.error];
      }
    } catch (e) {
      this.error("fetch filters", e);
      return { error: "Something went wrong" };
    }
  }

  async fetchTemplates({ filters, from }) {
    // if (q !== undefined) {
    //     localVarQueryParameter['q'] = q;
    // }

    // if (tags !== undefined) {
    //     localVarQueryParameter['tags'] = tags;
    // }

    // if (publishers !== undefined) {
    //     localVarQueryParameter['publishers'] = publishers;
    // }

    // if (page !== undefined) {
    //     localVarQueryParameter['page'] = page;
    // }

    // if (size !== undefined) {
    //     localVarQueryParameter['size'] = size;
    // }
    try {
      const qs = new URLSearchParams({
        ...filters,
        page: from,
        size: 50,
      }).toString();
      const res = await this.axios.get(`v2/search?${qs}`);

      if (res && res.data) {
        return res.data;
      } else {
        throw ["empty resp", res.error];
      }
    } catch (e) {
      this.error("fetch filters", e);
      return { error: "Something went wrong" };
    }
  }

  async fetchKitItem(path) {
    try {
      const qs = new URLSearchParams({
        path: path.replaceAll("/", `\\/`),
      }).toString();
      const res = await this.axios.get(`v1/get?${qs}`);

      if (res && res.data) {
        return res.data;
      } else {
        throw ["empty resp", res.error];
      }
    } catch (e) {
      this.error("fetch filters", e);
      return { error: "Something went wrong" };
    }
  }

  async fetchTemplateItem(id) {
    try {
      const res = await this.axios.get(
        `v1/templates/info/${encodeURIComponent(id)}`
      );

      if (res && res.data && res.data.data) {
        return res.data.data;
      } else {
        throw ["empty resp", res.data.error];
      }
    } catch (e) {
      this.error(`fetch template item ${id}`, e);
      return { error: "Something went wrong" };
    }
  }

  // async fetchRegionHints(provider) {
  //   if (!provider) return { regions: [] };

  //   try {
  //     const res = await this.axios.get(`provider/${provider}/region/list`);

  //     if (res && res.data && res.data.data) {
  //       return res.data.data;
  //     } else {
  //       throw ["empty resp", res.data.error];
  //     }
  //   } catch (e) {
  //     this.error("fetch filters", e);
  //     return { error: "Something went wrong" };
  //   }
  // }

  // async fetchZoneHints({ provider, region }) {
  //   if (!provider || !region) return { zones: [] };

  //   try {
  //     const res = await this.axios.get(
  //       `provider/${provider}/region/${region}/zone/list`
  //     );

  //     if (res && res.data && res.data.data) {
  //       return res.data.data;
  //     } else {
  //       throw ["empty resp", res.data.error];
  //     }
  //   } catch (e) {
  //     this.error("fetch filters", e);
  //     return { error: "Something went wrong" };
  //   }
  // }

  // async fetchInstanceHints({ provider, region, zone }) {
  //   if (!provider || (!region && !zone)) return { instances: [] };

  //   try {
  //     const res = await this.axios.get(
  //       `provider/${provider}/region/${
  //         provider === "gcp" && zone ? zone : region
  //       }/instance/list`
  //     );

  //     if (res && res.data && res.data.data) {
  //       return res.data.data;
  //     } else {
  //       throw ["empty resp", res.data.error];
  //     }
  //   } catch (e) {
  //     this.error("fetch filters", e);
  //     return { error: "Something went wrong" };
  //   }
  // }
}

const client = new Client();

export default client;
