<template>
  <div class="explore-content-wrapper">
    <KitHeader />
    <BreadCrumbs />
    <div class="container-fluid container-md">
      <div class="row pt-3 pt-lg-5">
        <FilterForm />
        <TemplateList />
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/breadcrumbs";
import KitHeader from "@/components/kitHeader";
import FilterForm from "@/components/explore/Filters";
import TemplateList from "@/components/explore/TemplateList";

export default {
  name: "Explore",
  components: {
    FilterForm,
    TemplateList,
    BreadCrumbs,
    KitHeader,
  },
};
</script>

<style lang="scss">
.explore-content-wrapper {
  // padding-top: 50px;
  min-height: calc(100vh - 185px);
}
</style>
