<template>
  <button
    :class="[
      'btn',
      showTooltip && !active ? 'btn-using-tooltip' : '',
      ...addClass,
    ]"
    @click.stop.prevent="$emit('click')"
    :disabled="!active"
    :type="type"
  >
    <div
      v-if="showTooltip && tooltipInfo"
      class="tooltip bs-tooltip-top"
      role="tooltip"
    >
      <div class="arrow"></div>
      <div class="tooltip-inner">
        {{ tooltipInfo }}
      </div>
    </div>
    <div v-if="isFetching" class="dot-opacity-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <slot v-else />
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    active: Boolean,
    addClass: [String, Array],
    type: {
      type: String,
      required: false,
      default: "button",
    },
    isFetching: {
      type: Boolean,
      required: false,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: false,
    },
    tooltipInfo: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-using-tooltip {
  position: relative !important;

  .tooltip {
    bottom: 100%;
    right: 0;
    transition: opacity 0.15s ease-in;
    .arrow {
      right: 25%;
    }
  }

  &:hover .tooltip {
    opacity: 1;
  }
}
.btn {
  .dot-opacity-loader {
    height: 15px;
    width: 85px;

    span {
      margin: 0 5px;
      background-color: white;
      height: 10px;
      width: 10px;
    }
  }
  &.btn-xs {
    .dot-opacity-loader {
      height: 10px;
      width: 40px;

      span {
        margin: 0 3px;
        background-color: white;
        height: 5px;
        width: 5px;
      }
    }
  }
}
</style>
