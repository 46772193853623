<template>
  <div class="header-searchbar">
    <div class="container-fluid container-md">
      <div class="row">
        <div class="col-md-12 d-flex flex-row">
          <div class="d-none d-md-block mr-5">
            <Logo />
          </div>
          <div class="flex-grow-1 d-flex flex-row">
            <TextInput
              placeholder="Search Kits"
              v-model="searchValue"
              type="text"
              :hasError="false"
              :showCleaner="true"
              :inputWrapper="['mb-0 header-searchbar-input']"
              :onSubmit="onSearchSubmit"
            />
            <Button
              :active="true"
              @click="onSearchSubmit"
              :addClass="['btn-primary header-searchbar-submit px-md-4']"
              :isFetching="false"
              type="button"
            >
              <span class="d-none d-md-block">Search</span>
              <span class="d-block d-md-none mdi mdi-magnify"></span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "./Logo";
import TextInput from "@/components/ui/TextInput";
import Button from "@/components/ui/Button";

export default {
  name: "SearchForm",
  components: { Logo, TextInput, Button },
  mounted() {
    this.searchValue = this.getNavParam;

    if (this.searchValue) {
      this.fetchAllData();
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isExploreRouteName() {
      return this.currentRouteName === "explore";
    },
    getNavParam() {
      return this.$route.query.search || "";
    },
    searchValue: {
      get() {
        return this.$store.getters["filters/getQueryString"];
      },
      set(value) {
        return this.$store.dispatch("filters/setQueryString", value);
      },
    },
  },
  methods: {
    onSearchSubmit() {
      if (
        this.isExploreRouteName &&
        (this.getNavParam === this.searchValue ||
          (!this.getNavParam.length && !this.searchValue))
      )
        return;

      this.fetchAllData();
      this.$router
        .push({
          name: "explore",
          query: {
            ...this.$route.query,
            search: this.searchValue ? this.searchValue : [],
          },
        })
        .catch(() => true);
    },
    fetchAllData() {
      this.$store.dispatch("templates/fetchFullItemsList", {
        from: 0,
      });
    },
  },
  watch: {
    currentRouteName() {
      if (!this.isExploreRouteName) this.searchValue = "";
    },
    getNavParam(newVal) {
      if (!newVal) this.searchValue = "";
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.header-searchbar {
  box-sizing: border-box;
  height: 70px;
  display: flex;
  align-items: center;
  border-bottom: 0.7px solid #000000;

  .header-searchbar-input {
    max-width: 650px;
    height: 50px;
    flex: 1;

    .form-control {
      height: 100%;
      border-radius: 0;
      background: rgba(255, 255, 255, 0.2);
      border: 0.2px solid rgba(0, 0, 0, 0.5);

      &::placeholder {
        color: #1b2443;
        font-size: 16px;
        line-height: 24px;
        font-weight: 250;
      }
    }
  }

  .header-searchbar-submit {
    height: 50px;
    background-color: #1b2443;
    border: none;
    font-weight: 400;
  }

  @include media-breakpoint-down(lg) {
    .header-searchbar-input {
      max-width: 700px;
    }
  }

  @include media-breakpoint-down(sm) {
    border-bottom: none;
    margin: 0 -15px;

    .header-searchbar-input,
    .header-searchbar-submit {
      height: 70px;
    }

    .form-control {
      border-top: none;
    }

    .header-searchbar-submit {
      font-size: 23px;
    }
  }
}
</style>
