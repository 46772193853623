<template>
  <div class="announce-wrap" :class="{ past }">
    <Initial v-show="!past" />
    <Past v-show="past" />
    <div class="d-flex flex-row align-items-center">
      <span v-show="past" class="past-text mr-2">Oh s**t, I want the...</span>
      <button
        v-show="!past"
        class="simple-btn simple-shadow size-md white"
        @click="past = !past"
      >
        <img
          src="@/assets/icons/arrow-pixel.svg"
          alt="arrow icon"
          class="mr-1 arrow-left"
        />
        <span class="text-center">Past</span>
      </button>
      <a
        href="https://h3lgw4sf0yl.typeform.com/to/rylDAgZh"
        target="_blank"
        class="simple-btn simple-shadow size-md ml-4"
        :class="{ white: past }"
      >
        <span class="text-center">Future</span>
        <img
          src="@/assets/icons/arrow-pixel.svg"
          alt="arrow icon"
          class="mt-1 ml-1"
        />
      </a>
    </div>
    <button
      v-if="past"
      class="btn simple-link classic d-flex flex-row white mt-4"
      @click="past = false"
    >
      <span>Go to Home Page</span>
    </button>
  </div>
</template>

<script>
import Initial from "./Initial.vue";
import Past from "./Past.vue";

export default {
  components: {
    Initial,
    Past,
  },
  data() {
    return {
      past: false,
    };
  },
};
</script>

<style lang="scss">
.announce-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  min-height: calc(100vh - 36px);
  background-color: #1b2443;
  justify-content: center;

  &.past {
    background: url(https://legacy.imagemagick.org/Usage/anim_mods/glitter_blue_tiled.gif);
  }

  .past-text {
    color: #fff;
    font-size: 20px;
  }

  img.arrow-left {
    margin-top: 3px;
    transform: rotate(180deg);
  }
}
</style>
