const modals = {
  namespaced: true,
  state: {
    ACTION_CONFIRM: false,
    actionCallback: null,

    GET_MONK: false,

    TEMPLATE_DEPLOY: false,
    TEMPLATE_DEPLOY_SIMPLE: false,

    ADD_PROFILE_TEMPLATE: false,
  },
  getters: {
    getModalState: (state) => (modalName) => state[modalName],
    getStopWorkloadID: (state) => state.stopWorkloadID,
  },
  mutations: {
    showModal: (state, modalName) => {
      state[modalName] = true;
    },
    hideModal: (state, modalName) => {
      state[modalName] = false;
      state.actionCallback = null;
    },
    showActionConfirmModal: (state, callback) => {
      state["ACTION_CONFIRM"] = true;

      if (callback && typeof callback === "function") {
        state.actionCallback = callback;
      }
    },
    showStopWorkloadModal: (state, stopWorkloadID) => {
      state.stopWorkloadID = stopWorkloadID;
      state.STOP_WORKLOAD = true;
    },
    hideRemoveWorkloadModal: (state) => {
      state.stopWorkloadID = "";
      state.STOP_WORKLOAD = false;
    },
  },
  actions: {
    showModal: ({ commit }, modalName) => commit("showModal", modalName),
    hideModal: ({ commit }, modalName) => commit("hideModal", modalName),

    showConfirmModal: ({ commit }, callback) => {
      commit("showActionConfirmModal", callback);
    },

    hideConfirmModal: ({ commit, state }, showCallback) => {
      if (
        state.actionCallback &&
        typeof state.actionCallback === "function" &&
        showCallback
      ) {
        state.actionCallback();
      }

      commit("hideModal", "ACTION_CONFIRM");
    },

    showStopWorkloadModal: ({ commit }, id) =>
      commit("showStopWorkloadModal", id),

    hideRemoveWorkloadModal: ({ commit }) => commit("hideRemoveWorkloadModal"),
  },
};

export default modals;
