import api from "@/api/userClient";
import router from "@/router";
import { fetched, fulfilled, refused } from "@/store/helpers";
import {
  LOGIN,
  REGISTRATION,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  CLOSE_ACCOUNT,
} from "./action-types";

export const actions = {
  async login({ commit, dispatch }, { email, password }) {
    commit(fetched(LOGIN));

    const resp = await api.login({ email, password });

    if (resp === true) {
      commit(fulfilled(LOGIN));

      await dispatch("user/fetchUser", null, { root: true });
      router.push({ name: "profile" });
    } else {
      const error = resp.error
        ? resp.error.reason
          ? resp.error.reason
          : resp.error
        : "";

      commit(refused(LOGIN), error);
    }
  },

  async register({ commit }, { email, password }) {
    commit(fetched(REGISTRATION));

    const resp = await api.register({ email, password });

    if (resp === true) {
      commit(fulfilled(REGISTRATION));
    } else {
      const error = resp.error
        ? resp.error.reason
          ? resp.error.reason
          : resp.error
        : "";

      commit(refused(REGISTRATION), error);
    }
  },

  async resetPassword({ commit }, { email }) {
    commit(fetched(RESET_PASSWORD));

    const resp = await api.resetPassword({ email });

    if (resp === true) {
      commit(fulfilled(RESET_PASSWORD));
    } else {
      const error = resp.error ? resp.error : "";

      commit(refused(RESET_PASSWORD), error);
    }
  },

  async changePassword({ commit }, { email }) {
    commit(fetched(CHANGE_PASSWORD));

    const resp = await api.resetPassword({ email });

    if (resp === true) {
      commit(fulfilled(CHANGE_PASSWORD));
    } else {
      const error = resp.error ? resp.error : "";

      commit(refused(CHANGE_PASSWORD), error);
    }
  },

  async logout({ dispatch }) {
    await api.logout();
    dispatch("user/remove", null, { root: true });
    router.push({ name: "login" });
  },

  async closeAccount({ commit, dispatch, rootGetters }, password) {
    commit(fetched(CLOSE_ACCOUNT));

    const passwordResp = await api.login({
      email: rootGetters["user/user"].email,
      password,
    });

    if (passwordResp === true) {
      const resp = await api.closeAccount();

      if (resp === true) {
        await dispatch("logout");
        commit(fulfilled(CLOSE_ACCOUNT));
      } else {
        const error = resp.error ? resp.error : "";

        commit(refused(CLOSE_ACCOUNT), error);
      }
    } else {
      const error = passwordResp.error ? passwordResp.error : "";

      commit(refused(CLOSE_ACCOUNT), error);
    }
  },
};
