<template>
  <div class="item-mock" :style="{ height: height + 'px' }">
    <div class="node-content">
      <div class="header">
        <div class="logo">
          <img :src="template.Icon" />
        </div>
        <span class="name">{{ template.Path }}</span>
      </div>
      <div class="main" />
    </div>
    <div
      v-for="p in ports"
      :key="p.id"
      class="port-item"
      :style="{ left: p.position[0] + 'px', top: p.position[1] + 'px' }"
      @click="addConnection(p.id, p.type)"
    >
      <span
        class="port-label"
        :class="{
          left: p.position[0] <= width / 2,
          right: p.position[0] > width / 2,
        }"
      >
        {{ p.id }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "KitPreview",
  props: ["template"],
  computed: {
    preview() {
      const template = this.template;

      const width = 255;
      const right = width - 8;
      const left = -6;

      const outports = [];
      const inports = [];

      for (const container of Object.keys(template.Containers)) {
        const names = container.split("/");
        const name = names[names.length - 1];
        inports.push({
          id: name,
        });
      }

      for (const k of Object.keys(template.Variables)) {
        const v = template.Variables[k];
        if (v.Name && v.Name.match(/host/)) {
          outports.push({
            id: v.Name,
          });
        }
      }

      const portHeight = 20;
      const height =
        Math.max(inports.length, outports.length) * portHeight + 90;

      const ports = {};

      let y = height - 30;
      for (const inport of inports) {
        const position = [right, y];
        ports[inport.id] = { ...inport, position, type: "in" };
        y -= portHeight;
      }

      y = height - 30;
      for (const outport of outports) {
        const position = [left, y];
        ports[outport.id] = { ...outport, position, type: "out" };
        y -= portHeight;
      }

      return {
        type: template.Path,
        portsType: "multiple",
        size: {
          w: width,
          h: height,
        },
        ports: ports,
      };
    },
    ports() {
      return this.preview.ports;
    },
    height() {
      return this.preview.size.h;
    },
    width() {
      return this.preview.size.w;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-mock {
  width: 255px;
  height: 309px;
  background-color: #fff;
  border-radius: 12px;
}
.item-mock {
  z-index: 10 !important;
  box-sizing: border-box;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &.node-item-active-gold {
    box-shadow: 0px 0px 4px 4px gold;
  }

  &.node-item-active-red {
    box-shadow: 0px 0px 4px 4px red;
  }

  .node-content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 2px;
    border-radius: 12px;
    background-color: #0315f9;

    $main-color: #73ccc3;
    $secondary-color: #ffce43;

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 12px 18px;
    }

    .logo {
      display: block;
      width: 24px;
      height: 24px;
      border-radius: 12px;
      background-color: #fff;
      position: relative;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .name {
      flex: 1;
      margin-left: 8px;
      font-size: 14px;
      color: #fff;
      font-weight: 600;
      line-height: 22px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .main {
      flex: 1;
      border-radius: 10px;
      background-color: #fff;
    }

    &:active,
    &.active {
      &:before {
        position: absolute;
        display: block;
        content: "";
        left: -10px;
        top: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background-color: #e8f3ff;
        border: 2px dashed #2563eb;
        border-radius: 10px;
        z-index: -1;
      }
    }
  }

  .port-item {
    position: absolute;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    background-color: #fff;
    border: 3px solid #2e343d;
    cursor: pointer;

    .port-label {
      position: absolute;
      font-size: 12px;
      font-weight: 600;
      min-width: 200px;
      &.right {
        right: 16px;
        text-align: right;
      }
      &.left {
        left: 16px;
        text-align: left;
      }
    }

    &.output {
      right: 0;
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
    &.input {
      left: 0;
    }
  }

  &.active {
    z-index: 15 !important;
  }

  &:active {
    z-index: 20 !important;
  }
}
</style>
