<template>
  <div
    :class="[
      'form-group custom-input-wrapper',
      hasError && 'has-danger',
      ...inputWrapper,
    ]"
  >
    <label v-if="label">{{ label }}</label>
    <input
      :class="[
        'form-control',
        hasError && 'form-control-danger valid',
        disabled && 'disabled',
      ]"
      :type="type"
      :value="value"
      :name="name || ''"
      :placeholder="placeholder"
      :disabled="disabled"
      @click.stop.prevent=""
      @input="handleInput"
      @keyup.enter="submit"
      @keyup.up="onKeyUp"
      @keyup.down="onKeyDown"
      @blur="onBlur"
      @focus="onFocus"
    />
    <i
      v-if="showCleaner && value && value.length"
      class="mdi mdi-close textinput-cleaner"
      @click.stop.prevent="onClean"
    />
    <div
      class="hints-wrapper"
      :style="label ? 'top: 69px;' : ''"
      v-if="(showHint || focused) && hints && computedList.length"
      ref="inputHintWrapper"
    >
      <span
        class="hint-item"
        :class="index === selectedHint && 'hint-selected'"
        v-for="(hint, index) in computedList"
        :key="hint + index"
        @click.stop.prevent="onHintPress(hint)"
      >
        {{ hint }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    value: String,
    placeholder: String,
    hasError: Boolean,
    onSubmit: Function,
    onStart: Function,
    onEnd: Function,
    label: String,
    inputWrapper: [String, Array],
    hints: Array,
    name: String,
    disabled: Boolean,
    showCleaner: Boolean,
  },
  name: "TextInput",
  data() {
    return {
      showHint: false,
      filteredHints: [],
      selectedHint: -1,
      focused: false,
    };
  },
  methods: {
    handleInput(e) {
      this.showHint = true;
      this.$emit("input", e.target.value);
    },
    submit() {
      if (
        (this.showHint || this.focused) &&
        this.computedList.length &&
        this.filteredHints !== -1
      ) {
        this.$emit("input", this.computedList[this.selectedHint]);
        this.showHint = false;
        this.focused = false;
        this.selectedHint = -1;
        return;
      }
      if (!this.onSubmit) return;
      this.onSubmit();
    },
    onBlur() {
      if (this.onEnd) this.onEnd();
      setTimeout(() => {
        this.focused = false;
        this.showHint = false;
      }, 200);
    },
    onFocus() {
      if (this.onStart) this.onStart();
      this.focused = true;
    },
    onHintPress(v) {
      this.$emit("input", v);
    },
    onKeyDown() {
      if (
        (!this.showHint && !this.focused) ||
        !this.computedList.length ||
        this.selectedHint >= this.computedList.length - 1
      )
        return;
      this.selectedHint = this.selectedHint + 1;
      if (this.$refs.inputHintWrapper && this.selectedHint > 2) {
        this.$refs.inputHintWrapper.scrollTop += 33; //hint item height
      }
    },
    onKeyUp() {
      if (
        (!this.showHint && !this.focused) ||
        !this.computedList.length ||
        this.selectedHint === -1
      )
        return;
      this.selectedHint = this.selectedHint - 1;
      if (this.$refs.inputHintWrapper && this.selectedHint >= 2) {
        this.$refs.inputHintWrapper.scrollTop -= 33; //hint item height
      }
    },
    onClean() {
      this.$emit("input", "");
      if (this.onSubmit) this.onSubmit();
    },
  },
  computed: {
    computedList() {
      if (
        this.focused &&
        !this.value &&
        this.hints &&
        this.hints.length &&
        !this.filteredHints.length
      )
        return this.hints;
      return this.filteredHints;
    },
  },
  watch: {
    value(newVal) {
      this.selectedHint = -1;

      if (!newVal) {
        this.filteredHints = [];
        this.showHint = false;
        return;
      }
      if (this.hints && this.hints.length) {
        this.filteredHints = this.hints.filter(
          (i) => i.toLowerCase().indexOf(newVal.toLowerCase()) !== -1
        );
      }
    },
  },
};
</script>

<style lang="scss">
input.text-input {
  padding: 8px;
  font-size: 14px;
  box-shadow: inset 2px 2px 0 0 rgba(0, 0, 0, 0.1);

  border: solid 1px black;
  min-width: 60%;
  font-size: 16px;
  font-family: Aeonik, sans-serif;

  &::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }

  &:focus {
    box-shadow: inset 4px 4px 0 0 rgba(124, 215, 194, 0.5);
    border: 1px solid #7cd7c2;
  }

  &.text-input-error {
    border: solid 1px #ff5e5e !important;
    color: #ff5e5e !important;

    &::placeholder {
      color: #ff5e5e !important;
    }

    &:focus {
      box-shadow: inset 4px 4px 0 0 rgba(255, 94, 94, 0.5);
      border: 1px solid #ff5e5e;
    }
  }
}
.custom-input-wrapper {
  position: relative;
  // transition: all 0.15s ease-in-out;

  .form-control.disabled {
    filter: brightness(0.9);
  }

  .textinput-cleaner {
    position: absolute;
    right: 9px;
    top: calc(50% - 10px);
    font-size: 15px;

    &:hover,
    &:active {
      color: #403c3c;
      cursor: pointer;
    }
  }

  .hints-wrapper {
    position: absolute;
    display: flex;
    top: 47px;
    flex-direction: column;
    width: 100%;
    max-height: 120px;
    overflow-y: scroll;
    z-index: 100;
    background-color: #f0efec;
    border: 1px solid #0315f9;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    transition: all 0.15s ease-in-out;

    .hint-item {
      padding: 0.5rem;
      font-size: 0.75rem;
      padding-left: 1rem;
      border-bottom: 1px solid #0315f9;
      background-color: #f0efec;

      &:hover,
      &.hint-selected {
        cursor: pointer;
        background-color: rgba(150, 138, 186, 0.2);
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>
