<template>
  <transition name="cookie-info-banner">
    <div
      v-if="shouldShow"
      id="cookie-info-notif"
      class="d-flex flex-column flex-md-row p-3 p-md-4"
    >
      <p class="text mb-0">
        We use cookies to improve your experience and personalize marketing. By
        clicking “Accept all,” you agree to our
        <a href="https://monk.io/cookie-policy" target="_blank" class="link">
          cookie policy
        </a>
      </p>
      <button
        class="flex-shrink-0 simple-btn simple-shadow blue size-md ml-md-3 mt-3 mt-md-0 p-3"
        @click="onCLick"
      >
        <span class="text-center">Accept All Cookies</span>
      </button>
    </div>
  </transition>
</template>

<script>
export default {
  name: "CookieInfo",
  data() {
    return {
      shouldShow: false,
    };
  },
  mounted() {
    this.check();
  },
  methods: {
    check() {
      this.shouldShow = Boolean(!localStorage.getItem("cookie-agreed"));
    },
    onCLick() {
      localStorage.setItem("cookie-agreed", false);
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#cookie-info-notif {
  box-sizing: border-box;
  position: fixed;
  right: 40px;
  bottom: 40px;
  width: 480px;
  max-width: calc(100vw - 80px);
  background-color: #cdd1ff;
  box-shadow: 0 18px 0 -10px #0f141d66;
  z-index: 1000;

  p.text {
    font-weight: 250;
    font-size: 12px;
    line-height: 18px;
    color: #000;
  }

  a.link {
    text-decoration: underline;
    color: inherit;
  }
}

.cookie-info-banner-enter-active {
  transition: all 0.3s;
}
.cookie-info-banner-leave-active {
  transition: all 0.3s;
}
.cookie-info-banner-enter {
  opacity: 0;
}
.cookie-info-banner-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
