<template>
  <div class="common-breadcrumbs">
    <div class="container-fluid container-md">
      <div class="row">
        <div class="col-md-12 common-breadcrumbs__content">
          <button
            class="breadcrumbs-link"
            :class="{ 'router-link-exact-active': $route.name === 'explore' }"
            @click.prevent="onExplore"
          >
            Explore<span class="mdi mdi-chevron-right ml-2"></span>
          </button>
          <!-- <router-link
            v-if="$route.name === 'search' || $route.name === 'kit-item'"
            to="/search"
            class="breadcrumbs-link"
          >
            Search<span class="mdi mdi-chevron-right ml-2"></span>
          </router-link> -->
          <span
            v-if="$route.name === 'kit-item'"
            class="breadcrumbs-link router-link-exact-active"
          >
            {{ $route.params.id
            }}<span class="mdi mdi-chevron-right ml-2"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onExplore() {
      if (window.history.length > 2 && this.$route.name === "kit-item")
        return this.$router.go(-1);

      if (this.$route.name === "explore") return;

      return this.$router.push("/explore");
    },
  },
};
</script>

<style lang="scss">
.common-breadcrumbs {
  background-color: #fff;

  .common-breadcrumbs__content {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .breadcrumbs-link {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #0315f9;
      cursor: pointer;
      background-color: transparent;
      border: none;
      outline: none;
      box-shadow: none;

      &.router-link-exact-active {
        font-size: 14px;
        color: #8089ff;
        cursor: default;
      }

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }
}
</style>
