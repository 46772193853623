import api from "@/api/client";
import {
  ADD_TEMPLATE,
  REMOVE_TEMPLATE,
  UPDATE_MAIN_INFO,
  UPDATE_COMPONENT_FIELD,
  UPDATE_COMPONENT_VAR,
  RESET,
  ADD_NODE,
  UPDATE_NODE,
  REMOVE_NODE,
  REGION_HINTS,
  ZONE_HINTS,
  INSTANCE_HINTS,
} from "./action-types";
import { fetched, fulfilled, refused } from "@/store/helpers";

export const actions = {
  [ADD_TEMPLATE]: ({ commit }, template) => commit(ADD_TEMPLATE, template),
  [REMOVE_TEMPLATE]: ({ commit }, templatePath) =>
    commit(REMOVE_TEMPLATE, templatePath),
  [UPDATE_MAIN_INFO]: ({ commit }, data) => commit(UPDATE_MAIN_INFO, data),
  [UPDATE_COMPONENT_FIELD]: ({ commit }, data) =>
    commit(UPDATE_COMPONENT_FIELD, data),
  [UPDATE_COMPONENT_VAR]: ({ commit }, data) =>
    commit(UPDATE_COMPONENT_VAR, data),
  [RESET]: ({ commit }) => commit(RESET),
  [ADD_NODE]: ({ commit }, templateName) => commit(ADD_NODE, templateName),
  [REMOVE_NODE]: ({ commit }, templateName) =>
    commit(REMOVE_NODE, templateName),
  [UPDATE_NODE]: ({ commit }, data) => commit(UPDATE_NODE, data),

  async fetchRegionHints({ commit }, provider) {
    commit(fetched(REGION_HINTS));

    const resp = await api.fetchRegionHints(provider);

    if (resp.error) {
      const error = resp.error ? resp.error : "";

      commit(refused(REGION_HINTS), error);
    } else {
      commit(fulfilled(REGION_HINTS), {
        items: resp.regions || [],
      });
    }
  },

  async fetchZoneHints({ commit }, { provider, region }) {
    commit(fetched(ZONE_HINTS));

    const resp = await api.fetchZoneHints({ provider, region });

    if (resp.error) {
      const error = resp.error ? resp.error : "";

      commit(refused(ZONE_HINTS), error);
    } else {
      commit(fulfilled(ZONE_HINTS), {
        items: resp.zones || [],
      });
    }
  },

  async fetchInstanceHints({ commit }, { provider, region, zone }) {
    commit(fetched(INSTANCE_HINTS));

    const resp = await api.fetchInstanceHints({ provider, region, zone });

    if (resp.error) {
      const error = resp.error ? resp.error : "";

      commit(refused(INSTANCE_HINTS), error);
    } else {
      commit(fulfilled(INSTANCE_HINTS), {
        items: resp.instances || [],
      });
    }
  },
};
