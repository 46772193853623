import { initialFetchState } from "@/store/helpers";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

const initialState = () => ({
  listMode: "deploy",
  groupFilter: [],
  fullItems: {
    list: [],
    foundCount: 0,
    pagination: {
      isFull: false,
      count: 50,
      start: 0,
    },
  },
  fullItemData: {},
  itemData: {},
  actions: {},
  variables: {},
  containers: {},
  fetchState: {
    itemData: initialFetchState(),
    fullItemsList: initialFetchState(),
  },
});

const templates = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default templates;
