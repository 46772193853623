<template>
  <div class="kit-header">
    <div class="container-fluid container-md">
      <div class="row">
        <div
          v-if="isExploreRouteName"
          class="col-md-12 py-3 kits-list__content"
        >
          <span class="text-results mb-2">Results: Node</span>
          <span class="text-found">{{ foundCount }} Kits found</span>
        </div>
        <div v-else class="col-md-12 py-3 kit-item__content">
          <div class="kit-icon">
            <img
              v-if="!emptyIcon && info.Icon && info.Icon !== '-'"
              :src="info.Icon"
              @error="emptyIcon = true"
            />
          </div>
          <div
            class="d-flex flex-column flex-md-row align-items-start align-items-md-center flex-wrap ml-4"
          >
            <span class="kit-name mr-md-3">{{ pathName }}</span>
            <span
              v-if="info && info.Publisher && info.Publisher !== '-'"
              class="official-label mt-1 mt-md-0"
              >Official publisher</span
            >
          </div>
          <button class="btn simple-btn blue px-5 ml-auto" @click="onDeploy">
            <span class="text-uppercase">Deploy</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emptyIcon: false,
    };
  },
  methods: {
    onDeploy() {
      this.$router.replace(
        {
          name: this.$route.name,
          query: {
            ...this.$route.query,
            template: this.info.Path || this.info.Name,
          },
        },
        () => this.$store.dispatch("modals/showModal", "TEMPLATE_DEPLOY_SIMPLE")
      );
    },
  },
  computed: {
    foundCount() {
      return this.$store.getters["templates/foundCount"];
    },
    info() {
      return this.$store.getters["templates/templateItemData"](this.pathName);
    },
    pathName() {
      return this.$route.name === "kit-item" ? this.$route.params.id : "";
    },
    isExploreRouteName() {
      return this.$route.name === "explore";
    },
  },
  watch: {
    isExploreRouteName() {
      this.emptyIcon = false;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.kit-header {
  min-height: 150px;
  background-color: #ccf4f0;

  .kits-list__content {
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .text-results {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #0f141d;
  }

  .text-found {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.5px;
    color: #1b2443;
  }

  .kit-item__content {
    min-height: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .kit-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    background-color: #ffffff;
    border: 1.5px solid #f1f2f5;
    border-radius: 35px;
    overflow: hidden;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .kit-name {
    font-weight: 700;
    font-size: 34px;
    line-height: 36px;
    color: #0c2665;
  }

  .official-label {
    padding: 2px 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #22b9aa;
    background-color: #e8f9f7;
    border-radius: 10px;
  }

  @include media-breakpoint-down(sm) {
    min-height: 110px;

    .kits-list__content,
    .kit-item__content {
      min-height: 110px;
    }

    .text-found {
      font-weight: 700;
      font-size: 28px;
      line-height: 48px;
      color: #0d9e90;
    }

    .kit-icon {
      width: 60px;
      height: 60px;
    }

    .kit-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
    }

    .official-label {
      font-weight: 500;
      font-size: 10.5px;
      line-height: 15px;
      color: #0d9e90;
    }
  }
}
</style>
