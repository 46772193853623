<template>
  <div class="common-header">
    <header class="header-top">
      <div class="container-fluid container-md">
        <div class="row">
          <nav class="col-md-12 d-flex flex-column">
            <Logo class="d-md-block hidden-logo" />
            <div class="mobile-nav-actions d-flex d-md-none">
              <Logo />
              <button class="btn p-0 m-0 toggle-btn" @click="toggleMenu">
                <span class="mdi mdi-menu"></span>
              </button>
            </div>
            <div
              class="nav-items pb-4 pb-md-0"
              :class="{ toggled: menuVisible }"
            >
              <router-link class="nav-link" :to="{ name: 'explore' }">
                <span class="menu-title"> Explore </span>
              </router-link>
              <a
                href="https://docs.monk.io/docs/publishers/"
                target="_blank"
                class="nav-link"
              >
                <span class="menu-title">Publish</span>
              </a>
              <a
                href="https://monk.io/contributors/"
                target="_blank"
                class="nav-link"
              >
                <span class="menu-title">Contributors</span>
              </a>
              <button
                @click="onGetClick"
                class="btn simple-btn simple-shadow blue ml-3 ml-md-auto mt-3 mt-md-0"
              >
                <span>Get Monk</span>
              </button>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <HeaderSearchBar />
  </div>
</template>

<script>
import HeaderSearchBar from "./SearchBar";
import Logo from "./Logo";

export default {
  name: "MainHeader",
  components: { HeaderSearchBar, Logo },
  data() {
    return {
      menuVisible: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    getNavParam() {
      return this.$route.query.search || "";
    },
    cartItems() {
      return this.$store.getters["cart/components"];
    },
    // showSearchBar() {
    //   return this.$route.name === "explore" || this.$route.name === "home";
    // },
  },
  methods: {
    isActiveCurrentRoute(name) {
      return this.currentRouteName === name;
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    hideMenu() {
      this.menuVisible = false;
    },
    onGetClick() {
      this.$store.dispatch("modals/showModal", "GET_MONK");
    },
  },
  watch: {
    currentRouteName() {
      this.hideMenu();
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.common-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1030;
  background-color: #f8f9ff;

  header.header-top {
    box-sizing: border-box;
    height: 80px;
    border-bottom: 0.7px solid #000000;

    nav {
      display: flex;
      flex-direction: row;

      .nav-items {
        height: 79px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .nav-link {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #1b2443;
        transition: all 0.2s;

        &:first-child {
          padding-left: 0;
        }

        &:hover {
          opacity: 0.7;
        }

        &:active {
          opacity: 1;
        }
      }
    }

    .mobile-nav-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-right: -15px;

      .toggle-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 79px;
        width: 80px;
        font-size: 32px;
        background-color: transparent;
        color: #0f141d;
      }
    }
  }

  .hidden-logo {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
    overflow: hidden;
    left: -9999px;
  }

  @include media-breakpoint-down(sm) {
    header.header-top {
      height: auto;
      min-height: 80px;

      nav {
        .nav-items {
          height: auto;
          min-height: 79px;
          display: none;
          flex-direction: column;
          align-items: flex-start;

          &.toggled {
            display: flex;
          }
        }

        .nav-link:first-child {
          padding-left: 15px;
        }
      }
    }
  }
}
</style>
