<template>
  <div class="main__encode">
    <span class="d-none d-xl-block line" />
    <div class="container">
      <div class="row">
        <div
          class="col-12 col-lg-5 col-xl-6 d-flex align-items-center justify-content-center justify-content-lg-start"
        >
          <img
            src="@/assets/img/main/main__encode.png"
            alt="encode illustration"
            class="illustration"
          />
        </div>
        <div
          class="col-12 col-lg-7 col-xl-6 d-flex flex-column align-items-start mt-5"
        >
          <h4 class="title">
            Encode DevOps expertise into the Kit and share it with the world.
          </h4>
          <p class="desc ml-large my-4 my-lg-5">
            Monk Script encodes DevOps expertise into a reusable packages.
            Easily inherit a ready component into a higher order kit. Stand on
            the shoulders of giants rather than spend time reinventing the
            wheel.
          </p>
          <div
            class="d-flex align-items-start align-items-lg-center flex-column flex-lg-row ml-large"
          >
            <a href="#" class="simple-btn simple-shadow blue">
              <span>Get Monk</span>
            </a>
            <a
              href="#"
              class="simple-link text-decoration-none black mt-4 mt-lg-0 ml-lg-4 d-flex flex-row"
            >
              <span>Join our Kit Developer Program</span>
              <img
                src="@/assets/icons/simple-arrow_black.svg"
                alt="arrow icon"
                class="simple-icon ml-2 mt-1"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__encode {
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
  padding-top: 80px;
  padding-bottom: 30px;
  background-color: #ccf4f0;
  color: #0f141d;
  position: relative;

  .title {
    line-height: 52px;
  }

  .desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 30px;
  }

  .line {
    position: absolute;
    bottom: 0;
    left: calc(50% + 15px);
    display: block;
    width: 0.7px;
    height: 370px;
    background-color: #585d6e;
  }

  .illustration {
    max-width: 80%;
    height: auto;
  }

  .ml-large {
    margin-left: 70px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 30px;
    padding-bottom: 60px;
    min-height: auto;

    .title {
      line-height: 104%;
    }

    .ml-large {
      margin-left: 0;
    }

    .illustration {
      max-width: 80%;
      max-height: 400px;
    }
  }

  @include media-breakpoint-down(md) {
    .illustration {
      max-height: 400px;
    }
  }
}
</style>
