import { initialFetchState } from "@/store/helpers";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { actions } from "./actions";

export const initialState = () => ({
  mainInfo: {
    namespace: "/xx",
    systemName: "system",
    clusterTag: "",
  },
  components: [],
  componentsData: {},
  nodes: [],
  regionHints: [],
  zoneHints: [],
  instanceHints: [],

  fetchState: {
    regionHints: initialFetchState(),
    zoneHints: initialFetchState(),
    instanceHints: initialFetchState(),
  },
});

const cart = {
  namespaced: true,
  state: initialState(),
  getters,
  mutations,
  actions,
};

export default cart;
