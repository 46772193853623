<template>
  <div
    class="circle-loader circle-loader-custom"
    :style="{ transform: `scale(${scale})` }"
  ></div>
</template>

<script>
export default {
  name: "CircleLoader",
  props: {
    scale: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style lang="scss">
.circle-loader.circle-loader-custom {
  margin: 0;
  width: 36px;
  height: 36px;

  &::after,
  &::before {
    border-width: 5px;
    border-color: #fff;
  }

  &::before {
    border-top-color: #ff32a1;
  }
}
</style>
