import Vue from "vue";
import VueRamda from "vue-ramda";
import VueClipboard from "vue-clipboard2";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VTooltip from "v-tooltip";
import Notifications from "vue-notification";
import VueGtag from "vue-gtag";

import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(VueRamda);
Vue.use(VueClipboard);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VTooltip);
Vue.use(Notifications);

Vue.use(
  VueGtag,
  {
    config: { id: "G-47PQFV86GV" },
    enabled: process.env.VUE_APP_USE_GTAG === "true",
  },
  router
);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
