<template>
  <div class="main__browse">
    <div class="container position-relative">
      <span class="d-none d-lg-block line" />
      <div class="row d-flex flex-column-reverse flex-lg-row">
        <div class="col-12 col-lg-6 d-flex flex-column align-items-start mt-5">
          <h4 class="title">
            The Power of Software and Infrastructure Combined
          </h4>
          <p class="desc my-4 my-md-5">
            Monk Kits are deployable assets for software, infrastructure, and
            configuration. Kits are parameterized, composable, and portable
            across environments (public, private, bare metal). Browse and
            publish at monkhub.io.
          </p>
          <a href="#" class="simple-btn simple-shadow">
            <span>Browse Monk Kits</span>
          </a>
        </div>
        <div
          class="col-12 col-lg-6 d-flex align-items-center justify-content-center"
        >
          <img
            src="@/assets/img/main/main__browse.png"
            alt="browse illustration"
            class="illustration"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid";

.main__browse {
  box-sizing: border-box;
  min-height: calc(100vh - 150px);
  padding: 90px 0;
  background-color: #f8f9ff;
  color: #0f141d;

  .title {
    line-height: 54px;
  }

  .desc {
    font-weight: 250;
    font-size: 20px;
    line-height: 30px;
  }

  .line {
    position: absolute;
    top: -90px;
    left: 15px;
    display: block;
    width: 0.7px;
    height: 110px;
    background-color: #585d6e;
  }

  .illustration {
    max-width: 100%;
    height: auto;
  }

  @include media-breakpoint-down(lg) {
    padding: 45px 0;
    min-height: auto;

    .title {
      line-height: 104%;
    }

    .line {
      top: -45px;
      height: 80px;
    }

    .illustration {
      max-width: 80%;
    }
  }
}
</style>
