<template>
  <div v-if="true">
    <router-view />
    <MainFooter />
  </div>
  <div v-else class="container-scroller" id="app">
    <MainHeader />
    <main v-if="isHomePage" class="home-page-wrapper">
      <router-view />
    </main>
    <div v-else-if="!isUserFlow" class="page-body-wrapper">
      <router-view />
    </div>
    <div
      v-else
      class="content-wrapper d-flex align-items-center justify-content-center auth"
    >
      <router-view />
    </div>
    <MainFooter />
    <Modals />
    <notifications
      group="main"
      v-if="authenticated"
      position="top center"
      width="350"
    >
      <template
        v-if="props.item.data && props.item.data.link && props.item.data.onLink"
        slot="body"
        slot-scope="props"
      >
        <div
          class="vue-notification-template vue-notification"
          :class="props.item.type || ''"
          @click="props.close"
        >
          <div class="notification-title">{{ props.item.title }}</div>
          <div class="notification-content">
            {{ props.item.text }}<br />
            <a class="notification-link" @click="onNotificationLink(props)">
              {{ props.item.data.link }}
            </a>
          </div>
        </div>
      </template>
    </notifications>
    <notifications
      group="auth"
      v-if="!authenticated"
      position="top center"
      width="350"
    />
    <CookieInfo />
  </div>
</template>

<script>
import MainHeader from "@/components/header/Main";
import MainFooter from "@/components/footer";
import Modals from "@/components/modals/Wrapper";
import CookieInfo from "@/components/CookieInfo";
export default {
  name: "app",
  components: {
    MainHeader,
    Modals,
    MainFooter,
    CookieInfo,
  },
  computed: {
    authenticated() {
      return this.$route.meta.requiresAuth;
    },
    showHeader() {
      return (
        this.$route.name === "explore" ||
        this.$route.name === "profile" ||
        this.$route.name === "compose"
      );
    },
    isHomePage() {
      return this.$route.name === "home" || this.$route.name === "main";
    },
    isUserFlow() {
      const profilePages = [
        "login",
        "register",
        "restore",
        "profile",
        "settings",
        "rewards",
      ];

      return profilePages.includes(this.$route.name);
    },
  },
  methods: {
    onNotificationLink(props) {
      props.item.data.onLink();
      props.close();
    },
  },
};
</script>

<style lang="scss">
// @import "./styles/theme/css/_reboot.scss";
@import "./styles/theme/vendors/iconfonts/materialdesignicons.min.css";
// @import "./styles/theme/vendors/iconfonts/simple-line-icons.css";
@import "./styles/theme/css/style.css";
@import "./styles/theme/scss/simple.scss";

body {
  background-color: #f8f9ff;
}

body,
html {
  overflow-x: unset !important;
}

#app {
  font-family: Aeonik, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #131a25;
}

.block-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 0.87;
  letter-spacing: 1.06px;
  color: #0315f9;
}

.access-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border-radius: 100px;
  background-color: #0315f9;
  border-color: #0315f9;
  font-size: 15px;
  letter-spacing: 1.55px;
  text-transform: uppercase;
  color: #fff;
}

.beta-label {
  padding: 2px 7px;
  margin-left: 5px;
  border-radius: 10px;
  font-weight: 900;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  background: linear-gradient(41deg, #6c44d9 0%, #e11886 100%);
}

.card .card-title {
  font-size: 1.5rem;
}

.home-page-wrapper,
.page-body-wrapper {
  padding-top: 150px;
}

h1,
h2,
h3,
h4 {
  font-family: Visuelt, sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;

  @include media-breakpoint-down(md) {
    line-height: 104%;
  }
}

h1 {
  font-size: 72px;

  @include media-breakpoint-down(md) {
    font-size: 48px;
  }
}

h2 {
  font-size: 64px;

  @include media-breakpoint-down(md) {
    font-size: 34px;
  }
}

h3 {
  font-size: 56px;

  @include media-breakpoint-down(md) {
    font-size: 34px;
  }
}

h4 {
  font-size: 44px;

  @include media-breakpoint-down(md) {
    font-size: 34px;
  }
}

.magic-btn {
  --main-color: #0315f9;
  --text-color: var(--main-color);
  --text-color-second: #fff;
  --mask-color: var(--main-color);
  --content-color: #f9f8f7;
  box-sizing: border-box;
  width: 290px;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--main-color);
  overflow: hidden;

  &.gray {
    --main-color: #aaa9a9;
    --text-color: #131a25;
    --mask-color: #131a25;

    &.darker {
      --content-color: #f0efec;
    }
  }

  &.gold {
    --main-color: #fecd51;
    --text-color: var(--main-color);
    --text-color-second: #0315f9;
    --mask-color: var(--main-color);
    --content-color: #0315f9;

    &.darker {
      --content-color: #fecb17;
      --main-color: #141b26;
      --text-color-second: #fecb17;
    }
  }

  &.red {
    --main-color: #f06439;
  }

  &.white {
    --main-color: #f9f8f7;
    --content-color: #0315f9;
    --text-color-second: #0315f9;
  }

  &.aqua {
    --main-color: #99e0d9;
    --content-color: #99e0d9;
    --text-color: #141b26;
    --text-color-second: var(--main-color);
    --mask-color: #292f39;
  }

  &.dark-blue {
    --content-color: transparent;
    --main-color: #f9f8f7;
    --text-color-second: #0315f9;
  }

  &.vertical {
    .magic-btn-arrows {
      flex-direction: column;
    }

    &:hover {
      .magic-btn-arrows {
        transform: translateY(100px);
      }
    }
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  .magic-btn-content {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
    font-family: Visuelt, sans-serif;
    color: var(--text-color);
    border-right: 1px solid var(--main-color);
    position: relative;
    transition: color 0.25s;
    z-index: 100;
    background-color: var(--content-color);

    span {
      z-index: 10;
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: var(--mask-color);
      bottom: -100%;
      z-index: 1;
      transition: all 0.5s;
    }
  }

  .magic-btn-arrows {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    overflow: hidden;
    transition: all 0.5s;
    z-index: 10;

    .arrow-item {
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;

      img {
        width: 50px;
        height: auto;
      }
    }
  }

  &:hover {
    .magic-btn-content {
      color: var(--text-color-second);
      &:before {
        bottom: 0px;
      }
    }
    .magic-btn-arrows {
      overflow: visible;
      transform: translateX(100px);
    }
  }
}

.vue-notification-group {
  top: 15px !important;

  .vue-notification {
    border-left: none;
    border-radius: 5px;
    padding: 10px 10px 10px 50px;
    background: none;
    background-color: #4d7cff;
    background-repeat: no-repeat;
    background-position: 10px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=);

    .notification-content {
      word-break: break-all;
    }

    .notification-title {
      font-family: arial, sans-serif;
      font-size: 14px;
      margin: 0 0 7px;
      background: 0 0;
      color: inherit;
      line-height: inherit;
      letter-spacing: normal;
      font-weight: normal;
    }

    .notification-link {
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }

    &.warn {
      background-repeat: no-repeat;
      background-position: 10px;
      background-color: #f29d56;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=);
    }

    &.error {
      background-repeat: no-repeat;
      background-position: 10px;
      background-color: #ff5e5e;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=);
    }

    &.success {
      background-repeat: no-repeat;
      background-position: 10px;
      background-color: #6fd96f;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==);
    }
  }
}

.prism-editor-wrapper {
  textarea {
    font-family: "Lineto", monospace !important;
  }

  pre {
    font-family: "Lineto", monospace !important;
    color: #909cb5;
  }
}

.navigation-change-enter-active,
.navigation-change-leave-active {
  transition: opacity 0.2s ease;
}
.navigation-change-enter,
.navigation-change-leave-to {
  opacity: 0;
}

.auth {
  min-width: 100vw !important;
  min-height: 100vh !important;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #f0efec;

  @media (max-width: 575px) {
    padding-top: 60px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
