<template>
  <div class="announce-past pt-2">
    <img
      src="https://pbs.twimg.com/media/E1xeXRhUYAEPKSQ.jpg"
      class="past-img mb-5"
    />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.announce-past {
  .past-img {
    max-height: 70vh;
    max-width: 100%;
  }
}
</style>
